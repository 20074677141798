/* eslint-disable @typescript-eslint/ban-types */
import { ISgConnectUserInfo } from '../../../common/auth/bus-topics';
import { SgBootstrapThemeExtended } from '../../../common/constants';
import { SgmNotification } from '../components/Notification/notification.types';
import { IPartialIncident } from '../components/PartialIncident/PartialIncident';

export interface ISgwtAccountCenterProps extends ISgwtAccountCenterDispatchProps {
  authentication: string;
  availableLanguages: string;
  debug?: boolean;
  environment: string;
  feedbackRequested: boolean;
  hideCurrentLanguage: boolean;
  hideServicesLink: boolean;
  i18n?: any;
  language: string;
  mode?: string;
  navigateAs?: boolean;
  navigateAsList?: IOnBehalfUser[];
  navigateAsUser?: IOnBehalfUser;
  producerCode?: string;
  showLanguagesSelection: boolean;
  showNavigateAsModal: boolean;
  showPersonalisation: boolean;
  showSignInButton: boolean;
  signOutLink?: string;
  themeSwitcher?: boolean;
  userDropdownLinks?: IUserDropdownLink[];
  user: ISgwtAccountCenterUser | null;
}

export interface ISgwtAccountCenterDispatchProps {
  onHideCustomLinksCard: (data: {}) => void;
  onLanguageChanged: ({ language }: { language: string }) => void;
  onNavigateAsLinkClicked: (data: {}) => void;
  onNavigateAsSelectUser: (data: { user: IOnBehalfUser }) => void;
  onNewIncident: (data: { incident: IPartialIncident }) => void;
  onNewNotification: (data: { notification: SgmNotification }) => void;
  onPersonalisationClicked: (data: {}) => void;
  onReady: (data: {}) => void;
  onShowCustomLinksCard: (data: {}) => void;
  onSignIn: (data: { mail: string } | {}) => void;
  onSignInRequest: (data: {}) => void;
  onSignOut: (data: {}) => void;
  onStopNavigateAs: (data: { user: IOnBehalfUser }) => void;
  onThemeChanged: (newTheme: SgBootstrapThemeExtended) => void;
  onUnreadNotifications: (data: { notifications: SgmNotification[] }) => void;
  onUserDropdownLinkClicked: (data: { link: IUserDropdownLink }) => void;
}

export interface ISgwtAccountCenterUser {
  mail: string;
  name: string;
  userInfo?: ISgConnectUserInfo;
}

export interface IAuthenticationModeData {
  authenticationEndpoint: string | null;
  changePasswordLink: string | null;
  signOutLink: string | null;
}

export interface IAuthenticationInformation {
  homologation: IAuthenticationModeData;
  production: IAuthenticationModeData;
  [key: string]: IAuthenticationModeData;
}

export interface IOnBehalfUser {
  name: string;
  company: string;
}

export interface INavigateAs {
  active?: boolean;
  asUser?: IOnBehalfUser;
  list?: IOnBehalfUser[];
  onSelectUser: (user: IOnBehalfUser) => void;
  onStopNavigationAs: () => void;
  onNavigateAsLinkClicked: () => void;
}

interface IOnBehalfSelectorSortState {
  column: string;
  order: string;
}

export interface INavigateAsModalState {
  currentPage: number;
  pagesCount: number;
  from: number;
  to: number;
  search: string;
  sort: IOnBehalfSelectorSortState;
  filteredList: object[];
}

export interface ILinkForEnvironment {
  homologation: string;
  production: string;
  [key: string]: string;
}

export interface ISSOv1UserInfo {
  Username: string;
  FirstName: string;
  LastName: string;
  Email: string;
  IsInternal: boolean;
}

export interface IUserDropdownLink {
  label: string;
  link: string;
  target?: string;
}

export type SidePanelScreen = 'help-center' | 'user' | 'my-services'; // 'custom-links'

// Cookie defined in sgwt-widget-polyfills to store the SG Bootstrap theme
// https://sgithub.fr.world.socgen/DT-Widgets/sgwt-widget-polyfills/blob/master/packages/platform/src/context/WidgetContext.ts#L8
export const COOKIE_SG_DESIGN_SYSTEM_THEME = 'sgwt_sg-design-system_theme';
