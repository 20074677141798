import React, { useEffect } from 'react';

interface UserToolbarDropdownProps {
  className: string;
  rootElement?: HTMLDivElement | null;
  onHide: () => void;
  visible: boolean;
  children?: React.ReactNode;
}

const DEFAULT_TOP_POSITION = 48;

export const UserToolbarDropdown = ({
  className,
  rootElement,
  onHide,
  visible,
  children,
}: UserToolbarDropdownProps) => {
  useEffect(() => {
    const handleOutsideClick = (event: Event) => {
      if (rootElement && !rootElement.contains(event.target as Node)) {
        onHide();
      }
    };

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' || event.which === 27) {
        onHide();
      }
    };

    if (visible) {
      document.addEventListener('mousedown', handleOutsideClick, false);
      document.addEventListener('touchend', handleOutsideClick, false);
      document.addEventListener('keydown', handleKeydown, false);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, false);
      document.removeEventListener('touchend', handleOutsideClick, false);
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [visible, onHide, rootElement]);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`position-absolute card ${className}`}
      style={{
        left: 0,
        top: `${DEFAULT_TOP_POSITION}px`,
        zIndex: 1024,
      }}
    >
      <div className="dropdown-menu show">{children}</div>
    </div>
  );
};
