import React, { GetDerivedStateFromProps } from 'react';
import { flushSync } from 'react-dom';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_GLOBAL_LANGUAGE, BUS_SG_DESIGN_SYSTEM_THEME } from '../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../common/configuration';
import { SgBootstrapThemeExtended } from '../../common/constants';
import { SgwtWidgetName, startWidgetMonitoring } from '../../common/monitoring';
import { Translator } from '../../common/sgwt-i18n';
import {
  emptyObject,
  getBrowserLanguage,
  getSgwtConnectLibrary,
  isDebugForced,
  readCookie,
} from '../../common/sgwt-widgets-utils';
import { warnSSOv1NotSupportedAnymore } from '../../common/sgwt-widgets-utils';
import { widgetize, widgetPropsBoundEvent } from '../../common/widgetize';
import AccountCenter from './components/AccountCenter';
import { SgmNotification } from './components/Notification/notification.types';
import { IPartialIncident } from './components/PartialIncident/PartialIncident';
import translator from './shared/sgwt-account-center.i18n';
import {
  COOKIE_SG_DESIGN_SYSTEM_THEME,
  INavigateAs,
  IOnBehalfUser,
  ISgwtAccountCenterDispatchProps,
  ISgwtAccountCenterProps,
  ISgwtAccountCenterUser,
  IUserDropdownLink,
} from './shared/sgwt-account-center.types';

import './sgwt-account-center.scss';

export interface ISgwtAccountCenterPublicFields {
  changeLanguage: (newLanguage: string) => void;
  setUser: (user: ISgwtAccountCenterUser) => void;
  signOut: () => Promise<void>;
  setNavigateAsUser: (user: IOnBehalfUser) => void;
  showNavigateAs: () => void;
  requestFeedback: (includeDoNotAskAgain?: boolean) => void;
  closeRequestFeedback: () => void;
}

interface ISgwtAccountCenterState {
  currentUser: ISgwtAccountCenterUser | null;
  feedbackRequested: boolean;
  feedbackRequestIncludeIgnoreOption: boolean;
  language: string;
  mounted: boolean;
  navigateAsUser?: IOnBehalfUser;
  showNavigateAsModal: boolean;
  signoutInProgress: boolean;
  theme: SgBootstrapThemeExtended | null;
  user: ISgwtAccountCenterUser | null;
}

export type EventDetails =
  | { language: string }
  | { link: IUserDropdownLink }
  | { mail: string }
  | { notifications: SgmNotification[] }
  | { incident: IPartialIncident }
  | { user: IOnBehalfUser }
  // eslint-disable-next-line @typescript-eslint/ban-types
  | {};

function isSgwtAccountCenterEvent(
  props: Readonly<ISgwtAccountCenterDispatchProps>,
  value: string,
): value is keyof ISgwtAccountCenterDispatchProps {
  return (
    Object.keys(props).includes(value) && typeof props[value as keyof ISgwtAccountCenterDispatchProps] === 'function'
  );
}

export class SgwtAccountCenter extends React.Component<ISgwtAccountCenterProps, ISgwtAccountCenterState> {
  static contextType = WidgetConfigurationContext;
  public static is = 'sgwt-account-center';

  public get user(): ISgwtAccountCenterUser | null {
    return this.state?.user;
  }

  public get navigateAsUser(): IOnBehalfUser | null {
    return this.state?.navigateAsUser ?? null;
  }

  public get language(): string | null {
    return this.state?.language;
  }

  static getDerivedStateFromProps: GetDerivedStateFromProps<ISgwtAccountCenterProps, ISgwtAccountCenterState> = (
    props: ISgwtAccountCenterProps,
    state: ISgwtAccountCenterState,
  ) => {
    if (state.user && !props.authentication) {
      return {
        ...state,
        currentUser: state.user,
      };
    }

    if (state.navigateAsUser !== props.navigateAsUser && props.navigateAsUser) {
      return {
        ...state,
        navigateAsUser: props.navigateAsUser,
      };
    }

    if (!!props.user && state.user !== props.user) {
      return {
        ...state,
        user: props.user,
        currentUser: props.user,
      };
    }

    return null;
  };

  private translator: Translator = translator;
  private debugEnabled = false;
  private widgetConfiguration: WidgetConfiguration;
  private propsBoundEvent = widgetPropsBoundEvent(SgwtAccountCenter.is);
  private onWidgetReady = (): void => {
    this.props.onReady({});
  };

  constructor(props: ISgwtAccountCenterProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.debugEnabled = this.props.debug || isDebugForced();
    if (this.props.i18n) {
      for (const lang of Object.keys(this.props.i18n)) {
        this.translator.addMessages(lang, this.props.i18n[lang]);
      }
    }
    const availableLanguages: string[] | null = this.props.availableLanguages
      ? this.props.availableLanguages.split(',').map((s) => s.toLowerCase().trim())
      : null;
    const language = this.getInitializationLanguage(this.widgetConfiguration, availableLanguages, props.language);
    this.logDebug(`[language] Initialization Language: ${language}`);
    this.translator.changeCurrentLanguage(language);
    const initializationTheme = this.getInitializationTheme();
    this.logDebug(
      `[theme] Theme Switcher ${props.themeSwitcher ? 'enabled' : 'disabled'}, theme: ${initializationTheme}`,
    );
    this.state = {
      currentUser: props.user ?? null,
      feedbackRequested: false,
      feedbackRequestIncludeIgnoreOption: true,
      language,
      mounted: false,
      showNavigateAsModal: false,
      signoutInProgress: false,
      user: props.user ?? null,
      theme: props.themeSwitcher ? initializationTheme : null,
    };
    if (this.props.authentication === 'sso-v1') {
      warnSSOv1NotSupportedAnymore(SgwtWidgetName.ACCOUNT_CENTER);
    }
  }

  private getAvailableLanguages(): string[] {
    return this.props.availableLanguages
      ? this.props.availableLanguages.split(',').map((s) => s.toLowerCase().trim())
      : [this.state.language];
  }

  private getInitializationTheme(): SgBootstrapThemeExtended {
    // Read directly from the cookie, as
    const fromCookie = readCookie(COOKIE_SG_DESIGN_SYSTEM_THEME);
    if (!!fromCookie && ['standard', 'dark', 'system'].includes(fromCookie)) {
      return fromCookie as SgBootstrapThemeExtended;
    }
    return 'standard';
  }

  /**
   * Get the language used to initialize the Account Center, in order of priority:
   *   1. from the `language` attribute.
   *   2. from the `sgwt_language` cookie.
   *   3. if `availableLanguages` is defined, the browser language, if supported by the Service.
   *   4. the first language defined in the `availableLanguages` attribute.
   *   5. the browser language.
   *   6. English.
   */
  private getInitializationLanguage(
    widgetConfiguration: WidgetConfiguration,
    availableLanguages: string[] | null,
    language: string | undefined,
  ): string {
    if (language) {
      // Case #1 - if the language is provided as a prop, use it.
      return language;
    }
    const fromCookie = widgetConfiguration.context?.getLanguage();
    // Case #2 - check the language from the SGWT Cookie. If it exists and supported by the Service, use it.
    // If `availableLanguages` is undefined, then all languages are considered to be supported.
    if (!!fromCookie && (!availableLanguages || availableLanguages.includes(fromCookie))) {
      return fromCookie;
    }
    const browserLanguage = getBrowserLanguage();
    if (availableLanguages !== null && availableLanguages.length > 0) {
      // Case #3 - if the browser language is supported by the Service, use it.
      if (!!browserLanguage && availableLanguages.includes(browserLanguage)) {
        return browserLanguage;
      }
      // Case #4 - Use the first available language.
      return availableLanguages[0];
    }
    // Case #5 - Use the browser language of English.
    return browserLanguage || 'en';
  }

  public componentDidMount = (): void => {
    this.setState({ ...this.state, mounted: true });
    document.addEventListener(this.propsBoundEvent, this.onWidgetReady);
    this.logDebug('[DEBUG] Account center connected');
    startWidgetMonitoring(SgwtWidgetName.ACCOUNT_CENTER, null, {
      authentication: this.props.authentication || 'none',
      availableLanguages: this.props.availableLanguages,
      language: this.props.language,
      mode: this.props.mode,
      navigateAs: this.props.navigateAs,
      personalisation: this.props.showPersonalisation,
      producerCode: this.props.producerCode,
      showLanguagesSelection: this.props.showLanguagesSelection,
      showSignInButton: this.props.showSignInButton,
      themeSwitcher: this.props.themeSwitcher,
    });
  };

  public componentWillUnmount = (): void => {
    this.setState({ ...this.state, mounted: false });
    this.logDebug('[DEBUG] Account center disconnected');
    document.removeEventListener(this.propsBoundEvent, this.onWidgetReady);
  };

  private emitEvent = (name: string, detail: EventDetails): void => {
    const event = EVENTS_META.find((event) => event.name.endsWith(name));
    if (!event) {
      this.logDebug(`[DEBUG] event not found, event: ${name}`);
      return;
    }
    if (isSgwtAccountCenterEvent(this.props, event.functionName)) {
      this.props[event.functionName](detail as any);
      this.logDebug(
        `[DEBUG] Emit event "${SgwtAccountCenter.is}--${name} [details: ${detail ? JSON.stringify(detail) : 'n/a'}]."`,
      );
    } else {
      this.logDebug(`[DEBUG] functioName mismatches with account center props, functioName: ${event.functionName}`);
    }
  };

  public changeLanguage(newLanguage: string) {
    const languageSupportedByWidget = this.translator.changeCurrentLanguage(newLanguage);
    this.logDebug(`[bus] language changed to ${newLanguage}, publish to bus`);
    this.widgetConfiguration.bus.publish(BUS_GLOBAL_LANGUAGE, newLanguage);
    this.emitEvent('language-changed', { language: newLanguage });
    this.setState({ ...this.state, language: newLanguage });
    if (!languageSupportedByWidget && this.props.environment !== 'production') {
      console.warn(`The language "${newLanguage}" is unknown to the widgets.`);
    }
    // Change the language globally in cookie
    if (this.widgetConfiguration.context) {
      this.widgetConfiguration.context.setLanguage(newLanguage);
    } else {
      console.warn('SGWTWidgetConfiguration.context is not available');
    }
  }

  /**
   * Emit the event to change the theme, and change the theme in the Account Center.
   * The event contains two properties:
   *  - theme: the theme to apply, which is either "standard" or "dark".
   *  - themeChosen: the theme chosen by the user, which can be "standard", "dark" or "system".
   */
  private changeTheme = (themeChosen: SgBootstrapThemeExtended) => {
    const isSystemOnDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const themeToApply = themeChosen === 'system' ? (isSystemOnDarkTheme ? 'dark' : 'standard') : themeChosen;
    this.logDebug(`[theme] change theme to ${themeToApply} (choice: ${themeChosen})`);
    this.emitEvent('theme-changed', {
      theme: themeToApply,
      themeChosen,
    });
    this.setState({ ...this.state, theme: themeChosen });
    // Change bus - only push "standard" or "dark".
    this.widgetConfiguration.bus.publish(BUS_SG_DESIGN_SYSTEM_THEME, themeToApply);
    // Change cookie - use the SGWTWidgetConfiguration.context if it exists.
    if (this.widgetConfiguration.context) {
      this.widgetConfiguration.context.setSgDesignSystemTheme(themeChosen);
    } else {
      console.warn('SGWTWidgetConfiguration.context is not available');
    }
  };

  public setUser = (user: ISgwtAccountCenterUser) => {
    this.logDebug('[setUser] Set user', user);
    this.emitEvent('sign-in', user ? { mail: user.mail } : {});
    flushSync(() => {
      this.setState({ ...this.state, user, currentUser: user });
    });

    if (emptyObject(user) && !emptyObject(this.state.navigateAsUser)) {
      this.stopNavigationAs();
    }
  };

  private signIn = () => {
    if (this.props.authentication === 'sg-connect-v2') {
      const sgwtConnect = getSgwtConnectLibrary();
      if (sgwtConnect) {
        sgwtConnect.requestAuthorization();
      } else {
        // should not happen (or the application is not configured correctly, and therefore a modification should be done on the app side)
        this.logDebug(
          '[sign-in] The widget is set to sg-connect-v2, but we failed to find the sgwtConnect instance to request an authorization.',
        );
        this.emitEvent('sign-in-request', {});
      }
    } else {
      this.emitEvent('sign-in-request', {});
    }
  };

  private logDebug = (...messages: any[]) => {
    if (this.debugEnabled) {
      this.widgetConfiguration.log('[DEBUG]', ...messages);
    }
  };

  public beforeSignOutPromise = (): Promise<any> => {
    return Promise.resolve();
  };

  // Can we discard the SG Connect v2 token? Yes if:
  //   - sg-connect-v2 mode is set;
  //   - SGWT Connect library is available through:
  //     - the <sgwt-connect> widget;
  //     - the `sgwtConnect` instance is available in `window`.
  private tryToDiscardAuthorization = (withRedirect: boolean) => {
    if (!this.props.authentication || this.props.authentication !== 'sg-connect-v2') {
      // No authentication system, or at least not a SG Connect v2 one. So we stop.
      return;
    }
    // Search for the sgwtConnect instance in the page...
    const sgwtConnect = getSgwtConnectLibrary();
    if (!sgwtConnect) {
      this.logDebug(
        '[sign-out] The Account Center is NOT able to discard the SG Connect v2 authorization, the library was not found.',
      );
      return;
    }

    if (withRedirect) {
      this.logDebug(
        '[sign-out] Discarding the SG Connect v2 authorization and redirecting the user on the endSession page.',
      );
      sgwtConnect.discardAuthorization?.();
    } else {
      this.logDebug(
        '[sign-out] Discarding the SG Connect v2 authorization without redirecting the user on the endSession page.',
      );

      sgwtConnect.discardAuthorizationLocally?.();
    }
  };

  private redirectToLogoutLink = () => {
    if (this.state.mounted && this.props.signOutLink) {
      window.location.href = this.props.signOutLink;
    }
  };

  /**
   * Public method to sign-out, used also by the "Sign out" link. This will force the redirection to the logout page.
   */
  public signOut = async () => {
    this.signOutAndEventuallyRedirect(true);
  };

  private signOutAndEventuallyRedirect = async (withRedirect: boolean) => {
    if (this.state.signoutInProgress) {
      // The signout process can be triggered multiple times. For example, when the user clicks
      // on signout link, the Account Center will trigger the `.discardAuthorization()` function
      // of sgwtConnect, which will emit the discardAuthorization event that is catched by the
      // Account Center that will run the `signOutAndEventuallyRedirect(false)` function...
      // That's why we first check that we are not already in a process of signout before running
      // the rest of the process...
      return;
    }
    flushSync(() => {
      this.setState({ ...this.state, signoutInProgress: true });
    });
    this.logDebug(`[sign-out] Signing-out, with${withRedirect ? '' : 'out'} redirection...`);
    // First, if it has been defined, run the beforeSignOut promise...
    try {
      await this.beforeSignOutPromise();
    } catch (e) {
      this.logDebug('An error occurred before signing out', e);
    }

    // Now, try to discard the authorization for SG Connect v2 applications...
    this.tryToDiscardAuthorization(withRedirect);

    // Stop the navigation as if it was enabled...
    if (!emptyObject(this.state.navigateAsUser)) {
      flushSync(() => {
        this.stopNavigationAs();
      });
    }
    // Remove user information...
    if (this.state.user !== null) {
      this.emitEvent('sign-out', {});
    }
    flushSync(() => {
      this.setState(
        {
          ...this.state,
          user: null,
          currentUser: null,
        },
        () => {
          // Wait for the token to be discarded before the redirection or the onSignOut process to be done.
          setTimeout(() => {
            this.redirectToLogoutLink();
          }, 250);
        },
      );
    });
    flushSync(() => {
      this.setState({ ...this.state, signoutInProgress: false });
    });
  };

  public setNavigateAsUser = (user: IOnBehalfUser) => {
    if (emptyObject(this.state.user)) {
      return;
    }
    this.emitEvent('navigate-as-select-user', { user });
    this.setState({ ...this.state, navigateAsUser: user });
  };

  public showNavigateAs = () => {
    this.onNavigateAsLinkClicked();
    if (this.props.navigateAsList && this.props.navigateAsList.length > 0) {
      this.setState({ ...this.state, showNavigateAsModal: true });
    }
  };

  private hideNavigateAs = () => {
    this.setState({ ...this.state, showNavigateAsModal: false });
  };

  private onNavigateAsLinkClicked = () => {
    this.emitEvent('navigate-as-link-clicked', {});
  };

  public stopNavigationAs = () => {
    this.emitEvent('stop-navigation-as', { user: this.state.navigateAsUser });
    this.setState({ ...this.state, navigateAsUser: undefined });
  };

  public requestFeedback = (includeDoNotAskAgain = false) => {
    if (this.props.mode !== 'b2b2c') {
      // Feedback feature is disabled for b2b2c mode.
      this.setState({
        ...this.state,
        feedbackRequested: true,
        feedbackRequestIncludeIgnoreOption: includeDoNotAskAgain,
      });
    }
  };

  public closeRequestFeedback = () => {
    this.setState({ ...this.state, feedbackRequested: false });
  };

  render() {
    // In some specific contexts, such when the <sgwt-account-center> is embedded in an angular.js
    // ng-if directive, the component has a wrong behavior (cf issue #390). We can avoid this kind
    // of issue by bypassing the rendering process when the component is not mounted.
    if (!this.state.mounted) {
      return null;
    }
    const navigateAsConf: INavigateAs = {
      active: this.props.navigateAs,
      asUser: this.state.navigateAsUser,
      list: this.props.navigateAsList,
      onSelectUser: (user: IOnBehalfUser) => this.setNavigateAsUser(user),
      onStopNavigationAs: () => this.stopNavigationAs(),
      onNavigateAsLinkClicked: () => this.onNavigateAsLinkClicked(),
    };

    const availableLanguages = this.getAvailableLanguages();

    return (
      <AccountCenter
        authentication={this.props.authentication}
        availableLanguages={availableLanguages}
        debug={this.debugEnabled}
        environment={this.props.environment}
        feedbackRequested={this.state.feedbackRequested}
        feedbackRequestIncludeIgnoreOption={this.state.feedbackRequestIncludeIgnoreOption}
        hideCurrentLanguage={this.props.hideCurrentLanguage}
        hideNavigateAs={this.hideNavigateAs}
        hideServicesLink={this.props.hideServicesLink}
        language={this.state.language.toLowerCase()}
        languageFromAttributes={this.props.language}
        mode={this.props.mode}
        navigateAs={navigateAsConf}
        logDebug={this.logDebug}
        onConnection={(user) => this.setUser(user)}
        onFeedbackClosed={this.closeRequestFeedback}
        onLanguageChanged={(newLanguage) => this.changeLanguage(newLanguage)}
        onPersonalisationClicked={() => {
          this.emitEvent('personalisation-clicked', {});
        }}
        onSignIn={() => this.signIn()}
        onSignOut={() => this.signOut()}
        onSignOutWithoutRedirect={() => this.signOutAndEventuallyRedirect(false)}
        onThemeChanged={this.changeTheme}
        onUserDropdownLinkClicked={(link: IUserDropdownLink) => {
          this.emitEvent('user-dropdown-link-clicked', { link });
        }}
        producerCode={this.props.producerCode}
        showLanguagesSelection={this.props.showLanguagesSelection}
        showNavigateAs={this.showNavigateAs}
        showNavigateAsModal={this.state.showNavigateAsModal}
        showPersonalisation={this.props.showPersonalisation}
        showSignInButton={this.props.showSignInButton}
        signOutLink={this.props.signOutLink}
        translator={this.translator}
        theme={this.state.theme}
        userDropdownLinks={this.props.userDropdownLinks}
        user={this.state.currentUser}
        emitEvent={this.emitEvent}
      />
    );
  }
}

const EVENTS_META = [
  {
    name: `${SgwtAccountCenter.is}--ready`,
    functionName: 'onReady',
  },
  {
    name: `${SgwtAccountCenter.is}--language-changed`,
    functionName: 'onLanguageChanged',
  },
  {
    name: `${SgwtAccountCenter.is}--sign-in`,
    functionName: 'onSignIn',
  },
  {
    name: `${SgwtAccountCenter.is}--sign-in-request`,
    functionName: 'onSignInRequest',
  },
  {
    name: `${SgwtAccountCenter.is}--personalisation-clicked`,
    functionName: 'onPersonalisationClicked',
  },
  {
    name: `${SgwtAccountCenter.is}--user-dropdown-link-clicked`,
    functionName: 'onUserDropdownLinkClicked',
  },
  {
    name: `${SgwtAccountCenter.is}--sign-out`,
    functionName: 'onSignOut',
  },
  {
    name: `${SgwtAccountCenter.is}--navigate-as-link-clicked`,
    functionName: 'onNavigateAsLinkClicked',
  },
  {
    name: `${SgwtAccountCenter.is}--navigate-as-select-user`,
    functionName: 'onNavigateAsSelectUser',
  },
  {
    name: `${SgwtAccountCenter.is}--stop-navigation-as`,
    functionName: 'onStopNavigateAs',
  },
  {
    name: `${SgwtAccountCenter.is}--partial-incidents-new-incident`,
    functionName: 'onNewIncident',
  },
  {
    name: `${SgwtAccountCenter.is}--notifications-unread-notifications`,
    functionName: 'onUnreadNotifications',
  },
  {
    name: `${SgwtAccountCenter.is}--notifications-new-notification`,
    functionName: 'onNewNotification',
  },
  {
    name: `${SgwtAccountCenter.is}--hide-CustomLinks-card`,
    functionName: 'onHideCustomLinksCard',
  },
  {
    name: `${SgwtAccountCenter.is}--show-CustomLinks-card`,
    functionName: 'onShowCustomLinksCard',
  },
  {
    name: `${SgwtAccountCenter.is}--theme-changed`,
    functionName: 'onThemeChanged',
  },
  {
    name: `${SgwtAccountCenter.is}--show-user-card`,
    functionName: 'onShowUserCard',
  },
  {
    name: `${SgwtAccountCenter.is}--hide-user-card`,
    functionName: 'onHideUserCard',
  },
  {
    name: `${SgwtAccountCenter.is}--show-my-services-card`,
    functionName: 'onShowMyServicesCard',
  },
  {
    name: `${SgwtAccountCenter.is}--hide-my-services-card`,
    functionName: 'onHideMyServicesCard',
  },
  {
    name: `${SgwtAccountCenter.is}--show-languages-dropdown`,
    functionName: 'onShowLanguagesDropdown',
  },
  {
    name: `${SgwtAccountCenter.is}--hide-languages-dropdown`,
    functionName: 'onHideLanguagesDropdown',
  },
];

widgetize(
  SgwtAccountCenter,
  SgwtAccountCenter.is,
  {
    attributes: [
      { name: 'authentication', type: 'string' },
      { name: 'available-languages', type: 'string' },
      { name: 'debug', type: 'boolean' },
      { name: 'environment', type: 'string' },
      { name: 'feedback-requested', type: 'boolean' },
      { name: 'hide-current-language', type: 'boolean' },
      { name: 'hide-services-link', type: 'boolean' },
      { name: 'i18n', type: 'object' },
      { name: 'language', type: 'string' },
      { name: 'mode', type: 'string' },
      { name: 'navigate-as', type: 'boolean' },
      { name: 'navigate-as-list', type: 'object' },
      { name: 'navigate-as-user', type: 'object' },
      { name: 'producer-code', type: 'string' },
      { name: 'show-languages-selection', type: 'boolean' },
      { name: 'show-navigate-as-modal', type: 'boolean' },
      { name: 'show-personalisation', type: 'boolean' },
      { name: 'show-sign-in-button', type: 'boolean' },
      { name: 'sign-out-link', type: 'string' },
      { name: 'theme-switcher', type: 'boolean' },
      { name: 'user-dropdown-links', type: 'object' },
      { name: 'user', type: 'object' },
    ],
    events: EVENTS_META,
    deferredFunctions: [
      'beforeSignOutPromise',
      'changeLanguage',
      'closeRequestFeedback',
      'requestFeedback',
      'setNavigateAsUser',
      'setUser',
      'showNavigateAs',
      'signOut',
      'stopNavigationAs',
    ],
  },
  { shadow: false },
);
