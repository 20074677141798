import React from 'react';
import { SvgIcon } from '../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { INavigateAs } from '../shared/sgwt-account-center.types';

interface IEnvironmentTagProps {
  environment: string;
}

export const EnvironmentTag: React.FC<IEnvironmentTagProps> = ({ environment }: IEnvironmentTagProps) => (
  <div className="sgwt-account-center-tag sgwt-account-center-environment-tag btn btn-sm mx-1 mb-2">
    <i className="sgwt-widgets-icon icon-sm">
      <SvgIcon type="code" id="code_left" />
    </i>
    <span className="fs-12 text-small mx-1">{environment}</span>
    <i className="sgwt-widgets-icon icon-sm">
      <SvgIcon type="code" id="code_right" />
    </i>
  </div>
);

interface INavigateAsTagProps extends ITranslatorProps {
  navigateAs: INavigateAs;
  selectUserOnBehalf: () => void;
}

export const NavigateAsTag: React.FC<INavigateAsTagProps> = ({
  navigateAs,
  selectUserOnBehalf,
  translator,
}: INavigateAsTagProps) => {
  const clickBtn = () => {
    registerAccountCenterEvent('navigate-as.change-user-from-tag');
    selectUserOnBehalf();
  };

  return (
    <div className="btn-group btn-group-sm sgwt-account-center-tag sgwt-account-center-navigation-as-tag mb-2">
      <button
        className="btn btn-info btn-sm px-0"
        onClick={() => {
          navigateAs.onStopNavigationAs();
        }}
      >
        <label className="sr-only visually-hidden">{translator.translate('stopNavigatingAs')}</label>
        <i className="sgwt-widgets-icon icon-sm">
          <SvgIcon type="close" />
        </i>
      </button>
      <button type="button" className="btn btn-info dropdown-toggle dropdown-toggle-split px-1" onClick={clickBtn}>
        <span className="mr-1 me-1">
          {translator.translate('navigatingAs', {
            user: navigateAs.asUser!.name,
          })}
        </span>
        <span className="sr-only visually-hidden">{translator.translate('stopNavigatingAs')}</span>
      </button>
    </div>
  );
};
