import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../../common/configuration';
import { registerAccountCenterEvent, SgwtWidgetName } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { hasGrantedScope, warnSSOv1NotSupportedAnymore } from '../../../../common/sgwt-widgets-utils';
import { MY_SERVICES_API_REQUIRED_SCOPES, MY_SERVICES_LINKS } from '../../shared/sgwt-account-center.endpoints';
import { ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';
import { MyServicesButton } from '../parts';

interface IMyServicesLinkProps extends ITranslatorProps {
  authentication?: string;
  environment: string;
  forceMyServicesDirectLink: boolean;
  logDebug: (...messages: any[]) => void;
  onButtonClick?: () => void;
  user: ISgwtAccountCenterUser | null;
}

interface IMyServicesLinkState {
  directLink: boolean;
}

export class MyServicesLink extends React.Component<IMyServicesLinkProps, IMyServicesLinkState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IMyServicesLinkProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      directLink: !props.user,
    };
  }

  /**
   * We update the component only if the state or if the user props is changed.
   */
  shouldComponentUpdate(nextProps: IMyServicesLinkProps, nextState: IMyServicesLinkState) {
    return this.props.user !== nextProps.user || this.state.directLink !== nextState.directLink;
  }

  /**
   * The component is updated. We will check if we will be able to call the SGR "My Services" API. If no, this will be
   * a simple link, otherwise it will be a panel displaying the list of available services.
   */
  componentDidUpdate() {
    const { authentication, logDebug, user } = this.props;
    if (!user) {
      // User not connected, so we display the link.
      logDebug('[my-services:link] No user detected. Direct link is used.');
      return;
    }
    if (authentication === 'sso-v1') {
      // TODO Should not support SSO v1 anymore?
      logDebug('[my-services:link] SSO v1 support for My Services. API will be called.');
      warnSSOv1NotSupportedAnymore(SgwtWidgetName.ACCOUNT_CENTER);
    } else if (authentication === 'sg-connect-v2') {
      const scopes = MY_SERVICES_API_REQUIRED_SCOPES;
      let hasScope = undefined;
      for (const scope of scopes) {
        hasScope = hasGrantedScope(this.widgetConfiguration, scope);
        if (typeof hasScope === 'undefined' || !hasScope) {
          break;
        }
      }
      if (typeof hasScope === 'undefined') {
        // We were not be able to know if we have the required scope to call the API...
        logDebug(
          `[my-services:link] Cannot define if we have the required scopes "${scopes.join(' ')}". Direct link is used.`,
        );
      } else if (hasScope) {
        logDebug(`[my-services:link] The required scope "${scopes.join(' ')}" is granted. API will be called.`);
        this.setState({
          directLink: false,
        });
      } else {
        logDebug(`[my-services:link] The required scope "${scopes.join(' ')}" is not granted. Direct link is used.`);
      }
    } else {
      logDebug(`[my-services:link] No authentication set. Direct link is used.`);
    }
  }

  private handleClick = () => {
    if (this.state.directLink || this.props.forceMyServicesDirectLink) {
      registerAccountCenterEvent('my-services.click-link');
    } else if (this.props.onButtonClick) {
      this.props.onButtonClick();
    }
  };

  render() {
    return (
      <MyServicesButton
        directLink={this.state.directLink || this.props.forceMyServicesDirectLink}
        myServicesLink={MY_SERVICES_LINKS[this.props.environment]}
        title={this.props.translator.translate('button.myServices')}
        onClick={this.handleClick}
      />
    );
  }
}
