import { IAuthenticationInformation, ILinkForEnvironment } from './sgwt-account-center.types';

/**
 * Target for the "Account center" link.
 */
export const ACCOUNT_CENTER_LINKS: ILinkForEnvironment = {
  homologation: 'https://www-uat.sgmarkets.com/myaccount',
  production: 'https://www.sgmarkets.com/myaccount',
};

/**
 * API to change the user language on SG Markets side.
 */
export const SG_MARKETS_LANGUAGE_API: ILinkForEnvironment = {
  // homologation: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2/me/language/',
  homologation: 'https://sgmarkets-api-serviceboard-uat.fr.world.socgen/api/v3/users/me/do-change-language/',
  // TODO Change the production URL when the API will be available on production...
  production: 'https://sgmarkets-api-serviceboard.fr.world.socgen/api/v3/users/me/do-change-language/',
};

/**
 * Target for the "See all my services" link.
 */
export const MY_SERVICES_DOMAIN: ILinkForEnvironment = {
  homologation: 'https://www-uat.sgmarkets.com',
  production: 'https://www.sgmarkets.com',
};

export const MY_SERVICES_LINKS: ILinkForEnvironment = {
  homologation: `${MY_SERVICES_DOMAIN.homologation}/myservices`,
  production: `${MY_SERVICES_DOMAIN.production}/myservices`,
};

/**
 * This is the scope required to call the SGR "My Services" API.
 */
export const MY_SERVICES_API_REQUIRED_SCOPES = ['openid', 'profile'];

/**
 * Endpoint of the SGR "My Services" API.
 */
export const MY_SERVICES_API: ILinkForEnvironment = {
  homologation: 'https://api-s-uat.sgmarkets.com/services/insight/serviceboard/private/v2',
  production: 'https://api-s.sgmarkets.com/services/insight/serviceboard/private/v2',
};

// For NEW MY SERVICES SEARCH
export const MY_SERVICES_SEARCH_API: ILinkForEnvironment = {
  homologation: 'https://api-z-dev.sgmarkets.com/uat/services/sgm-search/private/v1/api/v1',
  production: 'https://api-z.sgmarkets.com/services/sgm-search/private/v1/api/v1',
};

export const MY_SERVICES_LEARN_MORE_SITE: ILinkForEnvironment = {
  homologation: 'https://www-uat.sgmarkets.com/myservices/news',
  production: 'https://www.sgmarkets.com/myservices/news',
};

// For NEW MY SERVICES SEARCH
export const MY_SERVICES_ADD_SERVICE_FORM: ILinkForEnvironment = {
  homologation: 'https://digital-uat.fr.world.socgen/sgmarkets-integration/#/LinkReferential/Form',
  production: 'https://digital.fr.world.socgen/sgmarkets-integration/#/LinkReferential/Form',
};

/**
 * Links for authentication.
 */
export const AUTHENTICATIONS_INFO: IAuthenticationInformation = {
  homologation: {
    authenticationEndpoint: null,
    changePasswordLink: 'https://sgconnect-hom.fr.world.socgen/#/change-password',
    signOutLink: 'https://sgconnect-hom.fr.world.socgen/#/logout',
  },
  production: {
    authenticationEndpoint: null,
    changePasswordLink: 'https://sso.sgmarkets.com/#/change-password',
    signOutLink: 'https://sso.sgmarkets.com/#/logout',
  },
};
