import React from 'react';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { emptyObject } from '../../../common/sgwt-widgets-utils';
import { ISgwtAccountCenterUser } from '../shared/sgwt-account-center.types';
import { SignOutLink } from './SignOutLink';

export interface IUserInfoProps extends ITranslatorProps {
  onSignOut: () => void;
  signOutLink: string | null;
  user: ISgwtAccountCenterUser | null;
}

export class UserInfo extends React.Component<IUserInfoProps> {
  render() {
    const { user } = this.props;
    if (user === null || emptyObject(user) || !user.mail) {
      return null;
    }
    const mailParts = user.mail.split('@');
    return (
      <div className="text-left justify-content-start text-small ml-2 ms-2 d-none d-md-block align-self-center sgwt-account-center-user-info">
        {user.name ? (
          <div
            className="text-capitalize text-truncate font-weight-medium fw-medium sgwt-account-center-user"
            data-testid="user-name-testid"
          >
            {user.name}
          </div>
        ) : (
          <div className="text-truncate sgwt-account-center-user">
            <span className="sgwt-account-center-user-mail">{mailParts[0]}</span>
            <span className="sgwt-account-center-user-mail-domain">@{mailParts[1]}</span>
          </div>
        )}
        <SignOutLink {...this.props} />
      </div>
    );
  }
}
