import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { addAuthenticationInfoInRequestHeader, checkResponseStatus } from '../../../../common/sgwt-widgets-utils';
import { MY_SERVICES_API, MY_SERVICES_SEARCH_API } from '../../shared/sgwt-account-center.endpoints';

const getOptions = (widgetConfiguration: WidgetConfiguration) =>
  addAuthenticationInfoInRequestHeader(
    {
      headers: {
        accept: 'application/json',
      },
      mode: 'cors',
      method: 'GET',
    },
    widgetConfiguration,
    'sg-connect-v2',
  );

export const searchServices = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
  language: string,
  searchValue: string,
) => {
  const api = `${MY_SERVICES_SEARCH_API[environment]}/searchs`;
  const lang = language === 'fr' ? 'fr' : 'en';
  const url = `${api}?searchTerm=${searchValue}&language=${lang}&pageSizeBySearchType=10`;

  return fetch(url, getOptions(widgetConfiguration))
    .then(checkResponseStatus)
    .then((response: any) => response.json());
};

export const getMyEssentials = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
  language: string,
) => {
  const url = `${MY_SERVICES_API[environment]}/services/essentials?language=${language === 'fr' ? 'fr' : 'en'}`;

  return fetch(url, getOptions(widgetConfiguration))
    .then(checkResponseStatus)
    .then((response: any) => response.json());
};

export const getBookmarks = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
) => {
  const url = `${MY_SERVICES_API[environment]}/bookmarks`;

  return fetch(url, getOptions(widgetConfiguration))
    .then(checkResponseStatus)
    .then((response: any) => response.json());
};

export const addBookmark = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
  referenceId: string,
  isSgmService: boolean,
) => {
  const url = `${MY_SERVICES_API[environment]}/bookmarks`;
  const options = addAuthenticationInfoInRequestHeader(
    {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify({
        origin: isSgmService ? 'SgmService' : 'NonSgmService',
        referenceId,
      }),
    },
    widgetConfiguration,
    'sg-connect-v2',
  );

  return fetch(url, options)
    .then(checkResponseStatus)
    .then((response: any) => response.json());
};

export const removeBookmark = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
  bookmarkId: number,
) => {
  const url = `${MY_SERVICES_API[environment]}/bookmarks/${bookmarkId}`;
  const options = {
    ...getOptions(widgetConfiguration),
    method: 'DELETE',
  };

  return fetch(url, options).then(checkResponseStatus);
};

export const getStories = async (
  widgetConfiguration: WidgetConfiguration,
  environment: 'homologation' | 'production',
) => {
  const url = `${MY_SERVICES_API[environment]}/learnmores?detailed=false`;

  return fetch(url, getOptions(widgetConfiguration))
    .then(checkResponseStatus)
    .then((response: any) => response.json());
};
