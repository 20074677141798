import React from 'react';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';

export interface ISignOutLinkProps extends ITranslatorProps {
  onSignOut: () => void;
  signOutLink: string | null;
}

export const SignOutLink: React.FC<ISignOutLinkProps> = (props: ISignOutLinkProps) => {
  const signout = () => {
    registerAccountCenterEvent('user-toolbar.click-sign-out');
    props.onSignOut();
  };

  return (
    <button
      onClick={signout}
      className="btn-signout border-0 p-0 btn btn-link text-left text-start font-weight-medium fw-medium justify-content-start btn-sm"
    >
      <>{props.translator.translate('signOut')}</>
    </button>
  );
};
