import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { BUS_USER_INFO } from '../../../../common/auth/bus-topics';
import { ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';

interface IUserInfoData {
  mail: string;
  origin_network: string;
}

export const isUserPartOfBeta = (
  user: ISgwtAccountCenterUser | null,
  widgetConfiguration: WidgetConfiguration,
): boolean => {
  const userInfo = widgetConfiguration.bus.dangerouslyGetCurrentValue<IUserInfoData>(BUS_USER_INFO);
  if (userInfo && userInfo.origin_network !== 'LAN') {
    return false;
  }
  const mail = (userInfo ? userInfo.mail : user ? user.mail : '').toLowerCase();
  const mailDomain = mail.split('@')[1];
  return mailDomain === 'sgcib.com' || mailDomain === 'socgen.com';
};
