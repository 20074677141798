import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';

const MAX_NOTIFICATIONS = 12;

interface AccountCenterButtonProps {
  onClick: () => void;
  title: string;
}

interface MyServicesButtonProps extends AccountCenterButtonProps {
  directLink: boolean;
  myServicesLink: string;
}

interface UserButtonProps extends AccountCenterButtonProps {
  notificationsCount: number;
}

interface LanguagesButtonProps extends AccountCenterButtonProps {
  language: string;
}

export const CustomLinksButton = ({ onClick, title }: AccountCenterButtonProps) => (
  <button
    className="btn btn-flat-primary ml-1 ms-1 btn-lg sgwt-account-center-personalization ps-2 pe-2"
    onClick={onClick}
    aria-haspopup="true"
    aria-expanded="false"
    aria-label={title || 'Custom Links'}
    title={title || 'Custom Links'}
  >
    <SvgIcon type="settings" className="icon" height={24} width={24} />
  </button>
);

export const MyServicesButton = ({ directLink, myServicesLink, onClick, title }: MyServicesButtonProps) => {
  if (directLink) {
    return (
      <a
        href={myServicesLink}
        className="btn btn-flat-primary ml-1 ms-1 btn-lg sgwt-account-center-my-services ps-2 pe-2"
        aria-label={title || 'My Services'}
        title={title || 'My Services'}
        onClick={onClick}
      >
        <SvgIcon type="apps" className="icon" height={24} width={24} />
      </a>
    );
  }
  return (
    <button
      className="btn btn-flat-primary ml-1 ms-1 btn-lg sgwt-account-center-my-services ps-2 pe-2"
      onClick={onClick}
      aria-haspopup="true"
      aria-expanded="false"
      aria-label={title || 'My Services'}
      title={title || 'My Services'}
    >
      <SvgIcon type="apps" className="icon" height={24} width={24} />
    </button>
  );
};

export const UserButton = ({ onClick, title, notificationsCount }: UserButtonProps) => {
  // We set a different margin, depending on the number of digits to display...
  const notifsLabel =
    notificationsCount === 0
      ? ''
      : notificationsCount > MAX_NOTIFICATIONS
        ? `${MAX_NOTIFICATIONS}+`
        : `${notificationsCount}`;
  return (
    <button
      className={`btn btn-flat-primary ml-1 ms-1 mr-${notifsLabel.length} btn-lg sgwt-account-center-user-info ps-2 pe-2`}
      onClick={onClick}
      aria-haspopup="true"
      aria-expanded="false"
      aria-label={title || 'My account'}
      title={title || 'My account'}
    >
      <SvgIcon type="account_circle" className="icon" height={24} width={24} />
      {notificationsCount > 0 && (
        <span className="badge badge-pill rounded-pill badge-socgen rounded-pill position-absolute d-block ms-3">
          {notifsLabel}
        </span>
      )}
    </button>
  );
};

export const LanguagesButton = ({ language, onClick, title }: LanguagesButtonProps) => (
  <button
    className="btn btn-flat-primary btn-lg btn-icon-text btn-icon-start text-uppercase ml-1 ms-1 pr-2 pe-2 sgwt-account-center-languages dropdown-toggle ps-2 pe-2"
    onClick={onClick}
    aria-haspopup="true"
    aria-expanded="false"
    aria-label={title || 'Languages selection'}
    title={title || 'Languages selection'}
  >
    <SvgIcon type="language" className="icon" height={24} width={24} />
    {language}
  </button>
);
