import React from 'react';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { IAbstractService, IBookmarkedService, ISgmService } from './my-services.types';

interface IServiceCardProps {
  isSgmService: boolean;
  onClick?: () => void;
  service: IAbstractService | IBookmarkedService;
}

export const ServiceCard: React.FC<IServiceCardProps> = (props: IServiceCardProps) => {
  const { service } = props;
  let visibility = null;
  let boxClasses = 'bg-secondary-alpha-md bg-secondary-alpha-10 text-secondary';
  if (props.isSgmService) {
    const sgmService = props.service as ISgmService;
    visibility = sgmService.Visibility;
    boxClasses = 'bg-white text-black';
  }

  const classes =
    'd-flex align-items-center justify-content-center flex-column btn-link shadow-none w-100 text-primary sgwt-account-center-my-service';
  const card = (
    <div
      className={`card card-raising ${boxClasses} d-flex justify-content-center align-items-center p-0 border sgwt-account-center-initials-box display-4 font-weight-medium`}
    >
      {service.Initials}
    </div>
  );
  const title = <div className="mt-2 text-center sgwt-account-center-service-title">{service.Title}</div>;

  const clickLink = () => registerAccountCenterEvent('my-services.click-essential-service', service.Title);

  const url = (service as IAbstractService).Url || (service as IBookmarkedService).PageUrl;

  return (
    <div className="col-3 card card-filling-secondary py-3" key={`my-service-${service.Title}`}>
      {!visibility || visibility === null ? (
        <a href={url} className={classes} target="_blank" onClick={clickLink} rel="noreferrer">
          {card}
          {title}
        </a>
      ) : (
        <button onClick={props.onClick} className={`${classes} pt-0 btn btn-link`}>
          {card}
          {title}
        </button>
      )}
    </div>
  );
};
