import React from 'react';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { ListOfNotifications, NoNotification } from '../Notification/NotificationsLists';
import { useSgmNotification } from '../Notification/SgmNotificationContext';

interface NotificationsSectionProps extends ITranslatorProps {
  language: string;
  producerCode?: string;
}

export const NotificationsSection = ({ language, producerCode, translator }: NotificationsSectionProps) => {
  const { notifications } = useSgmNotification();
  return (
    <div className="sgwt-notifications d-flex flex-column" style={{ overflowY: 'auto' }}>
      <h3 className="h6 mb-3 mx-4">{translator.translate('notifications.panelTitle')}</h3>
      <div className="flex-grow-1" style={{ overflowY: 'auto' }}>
        {notifications.length === 0 ? (
          <NoNotification translator={translator} />
        ) : (
          <ListOfNotifications
            notifications={notifications}
            language={language}
            producerCode={producerCode}
            translator={translator}
          />
        )}
      </div>
    </div>
  );
};
