import React from 'react';
import { BasicModal, BasicModalBody, BasicModalHeader } from '../../../common/components/BasicModal';
import { SearchField } from '../../../common/components/SearchField';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { INavigateAs, IOnBehalfUser } from '../shared/sgwt-account-center.types';
import { Pagination } from './Pagination';

export interface INavigateAsModalProps extends ITranslatorProps {
  navigateAs: INavigateAs;
  show: boolean;
  onClose: () => void;
  onNavigateAsSelectUser: (user: IOnBehalfUser) => void;
}

interface IOnBehalfSelectorSortState {
  column: string;
  order: string;
}

interface INavigateAsModalState {
  currentPage: number;
  pagesCount: number;
  from: number;
  to: number;
  search: string;
  sort: IOnBehalfSelectorSortState;
  filteredList: object[];
}

const ITEMS_PER_PAGE = 5;

export class NavigateAsModal extends React.Component<INavigateAsModalProps, INavigateAsModalState> {
  modalContent?: HTMLLIElement;

  constructor(props: INavigateAsModalProps) {
    super(props);
    this.state = {
      currentPage: 1,
      pagesCount: this.countPages(props.navigateAs.list || []),
      filteredList: props.navigateAs.list || [],
      from: 0,
      to: ITEMS_PER_PAGE - 1,
      search: '',
      sort: {
        column: '',
        order: '',
      },
    };
  }

  private countPages(list: IOnBehalfUser[]): number {
    return Math.max(Math.ceil(list.length / ITEMS_PER_PAGE), 1);
  }

  changePage(newPage: number) {
    const newFrom: number = (newPage - 1) * ITEMS_PER_PAGE;
    this.setState({
      currentPage: newPage,
      from: newFrom,
      to: newFrom + ITEMS_PER_PAGE - 1,
    });
  }

  /**
   * Filter the list with the search made by the user, then re-sort the list.
   **/
  private filterAndSortList(search: string, column: string, order: string): IOnBehalfUser[] {
    const filteredList = (this.props.navigateAs.list || []).filter((user: any) => {
      if (search) {
        const searchLC = search.toLowerCase();
        // TODO Search on every field? At least on company name?
        return (
          user.name.toLowerCase().includes(searchLC) || (user.company && user.company.toLowerCase().includes(searchLC))
        );
      }
      return true;
    });
    // Now order the list...
    return filteredList.sort((u1: any, u2: any) => {
      const a: string = u1[column];
      const b: string = u2[column];
      const factor = order === 'asc' ? -1 : 1;
      if (!a) {
        return -factor;
      }
      if (!b) {
        return factor;
      }
      return a.localeCompare(b) * factor;
    });
  }

  private searchValueChanged = (search: string): void => {
    const filteredList = this.filterAndSortList(search, this.state.sort.column, this.state.sort.order);

    this.setState({
      currentPage: 1,
      pagesCount: this.countPages(filteredList),
      from: 0,
      to: ITEMS_PER_PAGE - 1,
      filteredList: filteredList,
      search: search,
    });
  };

  private sortList(column: string) {
    let order = 'desc';
    if (this.state.sort.column === column) {
      order = this.state.sort.order === 'desc' ? 'asc' : 'desc';
    }
    const filteredList = this.filterAndSortList(this.state.search, column, order);

    this.setState({
      currentPage: 1,
      pagesCount: this.countPages(filteredList),
      from: 0,
      to: ITEMS_PER_PAGE - 1,
      filteredList: filteredList,
      sort: {
        column: column,
        order: order,
      },
    });
  }

  /**
   * Check if the click is made outside the modal content, so we can close it.
   */
  handleClick(event: Event) {
    if (this.modalContent && !this.modalContent.contains(event.target as Node)) {
      this.props.onClose();
    }
  }

  render() {
    const { navigateAs, show, translator } = this.props;
    if (!navigateAs.active || !show) {
      return null;
    }

    const rows = this.state.filteredList.map((user: any, index: number) => {
      const included: boolean = index >= this.state.from && index <= this.state.to;
      return included ? (
        <tr key={user.name}>
          <td>
            <button
              className="pl-0 btn btn-link"
              onClick={() => {
                this.props.onClose();
                this.props.onNavigateAsSelectUser(user);
              }}
            >
              {user.name}
            </button>
          </td>
          <td>
            <button
              className="pl-0 btn btn-link"
              onClick={() => {
                this.props.onClose();
                this.props.onNavigateAsSelectUser(user);
                registerAccountCenterEvent('navigate-as.select-user');
              }}
            >
              {user.company}
            </button>
          </td>
        </tr>
      ) : undefined;
    });

    return (
      <BasicModal onClose={this.props.onClose} size="lg">
        <BasicModalHeader title={translator.translate('navigateAs')} onClose={this.props.onClose} />
        <BasicModalBody>
          {/* Search form */}
          <SearchField
            onSearch={this.searchValueChanged}
            placeholder={translator.translate('searchPlaceholder')}
            searchValue={this.state.search}
          />

          <table className="table table-hover">
            <thead>
              <tr>
                <th
                  className={`order ${this.state.sort.column === 'name' ? 'order-' + this.state.sort.order : ''}`}
                  onClick={() => this.sortList('name')}
                >
                  {translator.translate('columnName')}
                </th>
                <th
                  className={`order ${this.state.sort.column === 'company' ? 'order-' + this.state.sort.order : ''}`}
                  onClick={() => this.sortList('company')}
                >
                  {translator.translate('columnCompany')}
                </th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
          {this.state.pagesCount > 1 && (
            <Pagination
              currentPage={this.state.currentPage}
              pagesCount={this.state.pagesCount}
              from={this.state.from}
              to={this.state.to}
              onPageChange={(page: number) => this.changePage(page)}
            />
          )}
        </BasicModalBody>
      </BasicModal>
    );
  }
}
