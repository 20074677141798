import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../../common/configuration';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { addBookmark, removeBookmark } from './my-services.api';
import { IMyServiceSearchSgmService, IMyServiceSearchSgService } from './my-services.types';

interface IServiceMiniCardProps extends ITranslatorProps {
  bookmarkId: number | null;
  bookmarkUpdated: (referenceId: string, bookmarkId: number | null) => void;
  environment: 'homologation' | 'production';
  firstInList: boolean;
  isSgmService: boolean;
  language: string;
  myServicesPage: string;
  onClick?: () => void;
  service: IMyServiceSearchSgService | IMyServiceSearchSgmService;
}

export class ServiceMiniCard extends React.Component<IServiceMiniCardProps> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IServiceMiniCardProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
  }

  updateBookmark = () => {
    const { isSgmService, service } = this.props;
    if (this.props.bookmarkId === null) {
      addBookmark(this.widgetConfiguration, this.props.environment, service.referenceId, isSgmService)
        .then((data: any) => {
          this.props.bookmarkUpdated(service.referenceId, data.Id);
        })
        .catch((err: Error) => {
          console.log('[my-services:api] Cannot set the bookmark', err);
          registerAccountCenterEvent('my-services.error.bookmarks.add', err.message);
        });
      registerAccountCenterEvent('my-services.bookmarks.add');
    } else {
      removeBookmark(this.widgetConfiguration, this.props.environment, this.props.bookmarkId)
        .then(() => {
          this.props.bookmarkUpdated(service.referenceId, null);
        })
        .catch((err: Error) => {
          console.log('[my-services:api] Cannot remove the bookmark', err);
          registerAccountCenterEvent('my-services.error.bookmarks.remove', err.message);
        });
      registerAccountCenterEvent('my-services.bookmarks.remove');
    }
  };

  render() {
    const { service } = this.props;
    const initials = service.initials;
    const hasAccess = true;
    const serviceUrl = '';
    const boxColors = this.props.isSgmService
      ? 'bg-white text-black'
      : 'bg-secondary-alpha-md text-secondary bg-secondary-alpha-10';

    const clickLink = () => {
      const eventName = hasAccess
        ? this.props.isSgmService
          ? 'found-in-sgmarkets'
          : 'found-in-societe-generale'
        : 'access-required';
      registerAccountCenterEvent(`my-services.click-${eventName}`, service.title);
    };

    return (
      <li className="list-group-item list-group-item-action py-0 sgwt-account-center-my-service-inline">
        <div className={`py-3 d-flex justify-content-between ${this.props.firstInList ? '' : 'border-top'}`}>
          <a
            href={service.url}
            className="text-primary fw-medium d-flex flex-grow-1 my-n3 ml-n3 ms-n3 py-3 pl-3 ps-3"
            target="_blank"
            onClick={clickLink}
            rel="noreferrer"
          >
            <div
              className={`py-2 mr-2 me-2 border d-inline-block text-center text-large text-uppercase font-weight-normal fw-normal sgwt-account-center-initials-box-inline ${boxColors}`}
            >
              {initials}
            </div>
            <div className="d-inline">
              <div>{service.title}</div>
              <div className="text-secondary">{service.category}</div>
            </div>
          </a>
          <div className="d-inline text-nowrap text-secondary">
            {hasAccess ? (
              <button className="btn btn-link ps-2 pe-2" onClick={this.updateBookmark}>
                <SvgIcon
                  className="icon"
                  type={this.props.bookmarkId === null ? 'star_outline' : 'star'}
                  width={20}
                  height={20}
                />
              </button>
            ) : (
              <a href={serviceUrl} target="_blank" className="btn btn-flat-danger px-2" rel="noreferrer">
                {this.props.translator.translate('myServices.requestAccess')}
              </a>
            )}
            {this.props.isSgmService && (
              <a href={serviceUrl} target="_blank" className="ml-2 ms-2" rel="noreferrer">
                <SvgIcon className="icon" type="info_outline" width={24} height={24} />
              </a>
            )}
          </div>
        </div>
      </li>
    );
  }
}
