import React from 'react';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { MY_SERVICES_LEARN_MORE_SITE } from '../../shared/sgwt-account-center.endpoints';
import { IStory } from './my-services.types';

interface IStoryCardProps {
  environment: 'homologation' | 'production';
  language: string;
  story: IStory;
  visible: number;
}

interface IStoriesProps extends ITranslatorProps {
  environment: 'homologation' | 'production';
  language: string;
  stories: IStory[];
}

interface IStoriesState {
  visible: number;
}

const TAG_LABELS: {
  en: { [key: string]: string };
  fr: { [key: string]: string };
} = {
  en: {
    about: 'Platform',
    story: 'Services',
    howto: 'How to',
  },
  fr: {
    about: 'Plate-forme',
    story: 'Services',
    howto: 'Guide',
  },
};

export const StoryCard: React.FC<IStoryCardProps> = ({ environment, language, story, visible }: IStoryCardProps) => {
  const fr = language === 'fr';
  let url = fr ? story.content_url_fr : story.content_url;
  if (!url) {
    url = `${MY_SERVICES_LEARN_MORE_SITE[environment]}/${story.id}/${fr ? 'fr' : 'en'}`;
  }

  const onStoryClick = () => registerAccountCenterEvent('my-services.click-on-story', `${story.id}`);
  const tagLabel = (fr ? TAG_LABELS.fr[story.tag] : TAG_LABELS.en[story.tag]) || story.tag;

  return (
    <div
      key={`story-${story.id}`}
      className="col-8 mb-4 stories-slider-card pl-4 ps-4 pr-2 pe-2"
      style={{ transform: `translateX(${-100 * visible}%)`, minHeight: 215 }}
    >
      <a className="card card-raising h-100" href={url} target="_blank" onClick={onStoryClick} rel="noreferrer">
        <div
          className="card-body p-3 bg-lvl2"
          style={{
            backgroundImage: `
              linear-gradient(0deg, rgba(81, 87, 87, 0.1) 60%, rgba(81, 87, 87, 0.5)),
              url('${story.image_url}')
            `,
            backgroundSize: 'cover',
          }}
          role="img"
          aria-label={fr ? story.title_fr : story.title}
        >
          <div className="badge badge-primary-alt badge-lg badge-prepend-square">{tagLabel}</div>
        </div>
        <div className="card-footer p-3 bg-secondary-alpha-sm bg-secondary-alpha-05">
          <h5 className="font-weight-semibold text-truncate-2 mb-0">{fr ? story.title_fr : story.title}</h5>
        </div>
      </a>
    </div>
  );
};

export class Stories extends React.Component<IStoriesProps, IStoriesState> {
  constructor(props: IStoriesProps) {
    super(props);
    this.state = {
      visible: 0,
    };
  }

  previous = () => this.setState({ visible: this.state.visible - 1 });
  next = () => this.setState({ visible: this.state.visible + 1 });

  render() {
    return (
      <div className="mt-2">
        <h3 className="h6 mb-3">{this.props.translator.translate('myServices.learn-more')}</h3>
        <div className="stories-slider d-flex flex-nowrap mx-n4">
          {this.props.stories.map((story: IStory) => (
            <StoryCard
              key={`story-${story.id}`}
              environment={this.props.environment}
              language={this.props.language}
              story={story}
              visible={this.state.visible}
            />
          ))}
          {this.state.visible > 0 && (
            <button
              className="stories-slider-control stories-slider-previous border-0 px-0 bg-secondary-alpha-lg bg-secondary-alpha-20 text-secondary"
              onClick={this.previous}
            >
              <SvgIcon type="keyboard_arrow_left" height={60} width={60} />
            </button>
          )}
          {this.state.visible < this.props.stories.length - 1 && (
            <button
              className="stories-slider-control stories-slider-next border-0 px-0 bg-secondary-alpha-lg bg-secondary-alpha-20 text-secondary"
              onClick={this.next}
            >
              <SvgIcon type="keyboard_arrow_right" height={60} width={60} />
            </button>
          )}
        </div>
      </div>
    );
  }
}
