import React from 'react';
import { SvgIcon } from '../../../common/components/SvgIcon';

export interface IPaginationProps {
  currentPage: number;
  pagesCount: number;
  from: number;
  to: number;

  onPageChange(page: number): void;
}

export const Pagination: React.FC<IPaginationProps> = (props: IPaginationProps) => {
  const { currentPage, pagesCount } = props;

  const changePage = (newPage: number) => {
    if (newPage > 0 && newPage <= pagesCount && newPage !== currentPage) {
      props.onPageChange(newPage);
    }
  };

  const firstPage = currentPage === 1;
  const lastPage = currentPage === pagesCount;

  const indexes = [];
  const minIndex = Math.max(1, currentPage - 2);
  const maxIndex = Math.min(pagesCount, currentPage + 2);
  for (let i = minIndex; i <= maxIndex; i++) {
    indexes.push(i);
  }

  return (
    <nav className="float-right float-end">
      <ul className="pagination pagination-outline-primary">
        <li className={`page-item mr-2 me-2 ${firstPage ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => changePage(1)}>
            <i className="sgwt-widgets-icon icon-sm">
              <SvgIcon type="keyboard_arrow_left" />
            </i>
          </button>
        </li>
        {indexes.map((page: number) => (
          <li key={`page-${page}`} className={`page-item ${page === currentPage ? 'active' : ''}`}>
            <button className="page-link" onClick={() => changePage(page)}>
              {page}
            </button>
          </li>
        ))}
        <li className={`page-item ml-2 ms-2 ${lastPage ? 'disabled' : ''}`}>
          <button className="page-link" onClick={() => changePage(pagesCount)}>
            <i className="sgwt-widgets-icon icon-sm">
              <SvgIcon type="keyboard_arrow_right" />
            </i>
          </button>
        </li>
      </ul>
    </nav>
  );
};
