import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { ISGWTConnectOpenIDStandardClaims } from '@sgwt/connect-core/dist/src/SGWTConnectOpenIDUserInfo';
import {
  BUS_ACCESS_TOKEN,
  BUS_GLOBAL_LANGUAGE,
  BUS_USER_CONNECTION,
  BUS_USER_INFO,
  ISgwtUserConnectionStatus,
  SubscriptionHandle,
} from '../../../common/auth/bus-topics';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../common/configuration';
import { SgBootstrapThemeExtended } from '../../../common/constants';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import {
  emptyObject,
  executeWhenCustomElementIsPresent,
  getClaimsFromSgwtConnect,
  getSgwtConnectLibrary,
  isEmbeddedInAndroid,
  isEmbeddedInIOS,
  isInMode,
  whichMode,
} from '../../../common/sgwt-widgets-utils';
import { EventDetails } from '../sgwt-account-center';
import { ACCOUNT_CENTER_LINKS, AUTHENTICATIONS_INFO } from '../shared/sgwt-account-center.endpoints';
import {
  IAuthenticationModeData,
  INavigateAs,
  IOnBehalfUser,
  ISgwtAccountCenterUser,
  IUserDropdownLink,
  SidePanelScreen,
} from '../shared/sgwt-account-center.types';
import { EMPLOYEE_PORTAL_PRODUCER_CODE } from './Notification/notification.types';
import { SgmNotificationProvider } from './Notification/SgmNotificationContext';
import { ConnectedToolbar } from './ConnectedToolbar';
import { CustomLinks } from './CustomLinks';
import { DisconnectedToolbar } from './DisconnectedToolbar';
import { NavigateAsModal } from './NavigateAsModal';
import { HelpCenterButton, MyServicesSidePanel, UserSidePanel } from './parts';

interface IAccountCenterProps extends ITranslatorProps {
  authentication?: string;
  availableLanguages: string[];
  debug?: boolean;
  environment?: string;
  feedbackRequested: boolean;
  feedbackRequestIncludeIgnoreOption: boolean;
  hideCurrentLanguage?: boolean;
  hideNavigateAs: () => void;
  hideServicesLink?: boolean;
  language: string;
  languageFromAttributes?: string;
  mode?: string;
  navigateAs: INavigateAs;
  logDebug: (...messages: any[]) => void;
  onConnection: (user: ISgwtAccountCenterUser) => void;
  onFeedbackClosed: () => void;
  onLanguageChanged: (newLanguage: string) => void;
  onPersonalisationClicked: () => void;
  onSignIn: () => void;
  onSignOut: () => void;
  onSignOutWithoutRedirect: () => void;
  onThemeChanged: (newTheme: SgBootstrapThemeExtended) => void;
  onUserDropdownLinkClicked: (link: IUserDropdownLink) => void;
  producerCode?: string;
  showLanguagesSelection?: boolean;
  showNavigateAs: () => void;
  showNavigateAsModal: boolean;
  showPersonalisation: boolean;
  showSignInButton?: boolean;
  signOutLink?: string;
  theme: SgBootstrapThemeExtended | null;
  userDropdownLinks?: IUserDropdownLink[];
  user: ISgwtAccountCenterUser | null;
  emitEvent: (name: string, detail: EventDetails) => void;
}

interface IAccountCenterState {
  claims?: ISGWTConnectOpenIDStandardClaims;
  helpCenterExists: boolean;
  sidePanel: SidePanelScreen | null;
  token?: string;
}

export default class SgwtAccountCenter extends React.Component<IAccountCenterProps, IAccountCenterState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;
  private authenticationMode: IAuthenticationModeData | null = null;
  private signOutLink: string | null = null;
  private accessTokenSubscription?: string;
  private userConnectionSubscription?: SubscriptionHandle;
  private userInfoSubscription?: ISGWTConnectOpenIDStandardClaims;
  private sgConnectV2ManagedByBus = false;
  private defaultAccountCenterLink: string = ACCOUNT_CENTER_LINKS.homologation;
  private embeddedInWebView = false;
  private platform: 'desktop' | 'mobile' | 'ios' | 'android' = 'desktop';
  private environment: 'homologation' | 'production' = 'production';

  constructor(props: IAccountCenterProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      helpCenterExists: false,
      sidePanel: null,
    };
  }

  private setupComponent() {
    const envFromConfig = this.widgetConfiguration.environment.toLowerCase();
    this.environment = envFromConfig === 'production' || envFromConfig === 'prod' ? 'production' : 'homologation';
    this.authenticationMode = this.props.authentication ? AUTHENTICATIONS_INFO[this.environment] : null;
    this.signOutLink = this.authenticationMode ? this.authenticationMode.signOutLink : null;
    this.defaultAccountCenterLink = ACCOUNT_CENTER_LINKS[this.environment] || ACCOUNT_CENTER_LINKS.production;
    this.definePlatform();
    executeWhenCustomElementIsPresent('sgwt-help-center', { documentDefinition: false }, () => {
      this.setState({ helpCenterExists: true });
    });
  }

  private definePlatform() {
    if (isEmbeddedInIOS()) {
      this.platform = 'ios';
      this.embeddedInWebView = true;
    } else if (isEmbeddedInAndroid()) {
      this.platform = 'android';
      this.embeddedInWebView = true;
    } else {
      this.platform = 'desktop';
      this.embeddedInWebView = false;
    }
  }

  /**
   * "Guess" the name of the user from her/her email. Remove the domain part, as well as a potential "-ext" extension.
   */
  private getNameFromEmail(email: string) {
    return email.replace('-ext@', '@').split('@')[0].split('.').join(' ');
  }

  /**
   * A user has been detected (with SSO v1 authentication or SG Connect v2 without the usage of Widget bus).
   * We put some information in the Widgets bus, and call the
   */
  private onUserConnection(name: string, mail: string, claims?: any) {
    this.props.onConnection({
      name,
      mail,
    });
    // issue #25 MiniFooter - Force support for SSOv1 or SSOv2 no bus
    this.widgetConfiguration.bus.publish<ISgwtUserConnectionStatus>(BUS_USER_CONNECTION, {
      connected: true,
      mail,
      claims,
    });
  }

  /**
   * For SG Connect v2 mode and in case the Widget bus is not used, we try to find the sgwtConnect instance in `window`.
   */
  private fallbackForSGConnectV2AuthenticationWithoutBus() {
    this.props.logDebug('[authentication:sg-connect-v2] Fallback for SG Connect v2 support, without bus');
    if (this.sgConnectV2ManagedByBus) {
      // Bus was used in fact, maybe a concurrency race issue?
      return;
    }
    const sgwtConnect = getSgwtConnectLibrary();
    // Found it!
    if (sgwtConnect) {
      const user = getClaimsFromSgwtConnect(sgwtConnect);
      this.props.logDebug('[authentication:sg-connect-v2] sg-connect-v2-fallback, got user', user);
      if (user && user.sub) {
        this.onUserConnection(this.getNameFromEmail(user.sub), user.sub, user);
        this.props.logDebug('[authentication:sg-connect-v2] Publish user information on widgets bus');
      } else {
        // We are in a specific case: the sgwtConnect instance has been found, but the user does not exists - meaning
        // that sgwtConnect.isAuthorized() is false. We will wait for the event of token renewal...
        // This is useful for applications that make a silent authorization request (`renewAuthorization()`) in fact.
        sgwtConnect.on('renewAuthorizationSuccess', this.onRenewAuthorizationSuccess);
      }
    } else {
      this.props.logDebug(
        '[authentication:sg-connect-v2] sg-connect-v2 fallback. No global sgwtConnect library found...',
      );
    }
  }

  /**
   * Identify the user with SG Connect v2.
   * This is called when we register to the corresponding topic on Widget bus.
   * In case the bus is not used, we call the `fallbackForSGConnectV2AuthenticationWithoutBus`.
   **/
  private updateUserConnectionInformation(status?: ISgwtUserConnectionStatus) {
    this.sgConnectV2ManagedByBus = status !== undefined; // We know that the bus is used
    if (status && status.connected) {
      // user is connected.
      if (!this.props.user) {
        // Case 1: user just connected => emit sign-in event.
        const mail = status.mail || status.claims.sub;
        const user: ISgwtAccountCenterUser = {
          mail: mail,
          name: this.getNameFromEmail(mail),
        };
        setTimeout(() => {
          this.props.onConnection(user);
        });
      }
    } else {
      // User is not connected
      if (this.props.user) {
        // Case 2: user was connected. So it's a disconnection.
        // The sign-out was NOT made by the Account Center, so we do not want to redirect the user
        // to the SG Connect signout page.
        this.props.onSignOutWithoutRedirect();
      }
    }
  }

  /**
   * After getting the preferred language from the userInfo, we check if we have to apply it or not.
   * We apply it only if:
   *   - the language is not set from the widget attributes.
   *   - the language is not retrieved from the SGWT Cookie.
   *   - the preferred language is set and part of the available languages.
   * ? This function is called only when the userInfo has been fetched for the first time.
   */
  private applyPreferredLanguage(preferredLanguage: string | null | undefined) {
    // Case #1 - the attribute `language="xxx"` is set (`languageFromAttributes`) so it has the priority.
    if (this.props.languageFromAttributes) {
      this.props.logDebug(
        `[language-preferences] The language ${this.props.languageFromAttributes} is set from attributes.`,
      );
      return;
    }

    // Case #2 - the `preferredLanguage` from userInfo is not set, so we do nothing.
    if (!preferredLanguage) {
      this.props.logDebug('[language-preferences] No preferred language for this user.');
      return;
    }

    const { availableLanguages } = this.props;
    // Case #3 - The preferred language exists but is not supported by the current Service.
    if (!availableLanguages.includes(preferredLanguage)) {
      this.props.logDebug(`[language-preferences] The preferred language "${preferredLanguage}" is not available.`);
      return;
    }

    // Now, search the language from the Cookie, used to persist this preference.
    const languageFromCookie = this.widgetConfiguration.context?.getLanguage();
    // Case #4 - the language from the cookie exists and is part of the available languages.
    // We do nothing as it was already used on the initialization of the widget.
    if (!!languageFromCookie && availableLanguages.includes(languageFromCookie)) {
      return;
    }
    // Case #5 - the language from the cookie is not set, and the preferred language is not the current one
    // so we apply the language from the userInfo.
    if (preferredLanguage !== this.props.language) {
      this.props.logDebug(`Apply the new language "${preferredLanguage}" from userInfo.`);
      this.props.onLanguageChanged(preferredLanguage);
    }
  }

  /**
   * Authenticate using SG Connect v2 system.
   * We first try with the SG Widgets bus - then we try to find the sgwtConnect library in window object...
   **/
  private authenticateWithSgConnectV2() {
    this.props.logDebug('[authentication:sg-connect-v2] Authenticate using SG Connect v2');
    // Connect to the bus...
    this.accessTokenSubscription = this.widgetConfiguration.bus.subscribe<string>(
      BUS_ACCESS_TOKEN,
      (token?: string) => {
        this.props.logDebug('[authentication:sg-connect-v2] User token updated from Widgets bus', token);
        this.setState({ token });
      },
    ) as string | undefined;
    this.userConnectionSubscription = this.widgetConfiguration.bus.subscribe<ISgwtUserConnectionStatus>(
      BUS_USER_CONNECTION,
      (status?: ISgwtUserConnectionStatus) => {
        this.props.logDebug('[authentication:sg-connect-v2] User connection status updated from Widgets bus', status);
        this.updateUserConnectionInformation(status);
      },
    );
    this.userInfoSubscription = this.widgetConfiguration.bus.subscribe<ISGWTConnectOpenIDStandardClaims>(
      BUS_USER_INFO,
      (claims?: ISGWTConnectOpenIDStandardClaims) => {
        this.props.logDebug('[authentication:sg-connect-v2] User info claims updated from Widgets bus', claims);
        this.setState({ claims });
        this.applyPreferredLanguage(claims?.preferred_language as string | null | undefined);
      },
    ) as ISGWTConnectOpenIDStandardClaims | undefined;

    // In case the <sgwt-connect> widget is not used, we need to react to the discard authorization event.
    const sgwtConnect = getSgwtConnectLibrary();
    if (sgwtConnect) {
      sgwtConnect.on('authorizationDiscarded', this.onAuthorizationDiscarded);
    }
    // Not always the case, since the <sgwt-connect> widget may be used after the <sgwt-account-center>...
    // if (customElements.get('sgwt-connect') === undefined) {
    // Fallback if not using <sgwt-connect> (and therefore, the widget bus)...
    this.fallbackForSGConnectV2AuthenticationWithoutBus();
  }

  private onAuthorizationDiscarded = () => {
    // Since this sign out has NOT been triggered by the Account Center, we do not redirect the user to
    // the SG Connect endSession page.
    if (this.props.user) {
      this.props.onSignOutWithoutRedirect();
    }
  };

  private onRenewAuthorizationSuccess = () => {
    const sgwtConnect = getSgwtConnectLibrary();
    if (sgwtConnect) {
      const user = getClaimsFromSgwtConnect(sgwtConnect);
      this.props.logDebug(
        '[authentication:sg-connect-v2] sg-connect-v2-fallback, got user after a Token Renewal event',
        user,
      );
      if (user && user.sub) {
        this.onUserConnection(this.getNameFromEmail(user.sub), user.sub, user);
        this.props.logDebug('[authentication:sg-connect-v2] Publish user information on widgets bus');
      }
    }
  };

  public componentDidMount() {
    this.setupComponent();
    if (this.props.authentication === 'sg-connect-v2') {
      this.authenticateWithSgConnectV2();
    }
    // Get current language and publish it in the Widget Bus
    const currentLanguage = this.props.language || 'en';
    this.widgetConfiguration.bus.publish(BUS_GLOBAL_LANGUAGE, currentLanguage);
    this.props.logDebug(`[bus] publish language "${currentLanguage}" on bus`);
  }

  /**
   * Unmount. Deregister from bus.
   */
  public componentWillUnmount() {
    if (this.accessTokenSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.accessTokenSubscription);
    }
    if (this.userConnectionSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.userConnectionSubscription);
    }
    if (this.userInfoSubscription) {
      this.widgetConfiguration.bus.unsubscribe(this.userInfoSubscription);
    }
    const sgwtConnect = getSgwtConnectLibrary();
    if (sgwtConnect) {
      // We remove the listeners on sgwtConnect events.
      if (sgwtConnect.removeListener) {
        // SGWT Widgets is built with an old version of `events` library, which does not expose `.off` shortcut
        // (introduced in `3.0.0`) so we use the default `removeListener`.
        sgwtConnect.removeListener('authorizationDiscarded', this.onAuthorizationDiscarded);
        sgwtConnect.removeListener('renewAuthorizationSuccess', this.onRenewAuthorizationSuccess);
      } else if (sgwtConnect.off) {
        // Not really useful, as normally `removeListener` since `off` is a shortcut to `removeListener`...
        sgwtConnect.off('authorizationDiscarded', this.onAuthorizationDiscarded);
        sgwtConnect.off('renewAuthorizationSuccess', this.onRenewAuthorizationSuccess);
      } else {
        this.props.logDebug('[events] cannot deregister authorizationDiscarded and renewAuthorizationSuccess events.');
      }
    }
  }

  startNavigateAs() {
    this.setState({ sidePanel: null }, () => {
      this.props.showNavigateAs();
    });
  }

  /**
   * A click on a usertoolbar button has been made to open a Side bar.
   * As they share the same place, we should first check if the Help Center is opened and close it if necessary.
   * Then, we open the good panel, or close it if the button is the same, i.e. if the User panel is displayed and
   * the user click again on the User button, then the panel is closed.
   */
  displaySidePanel = (screen: SidePanelScreen | null) => {
    const element: any | null = document.querySelector('sgwt-help-center');
    const isHelpCenterOpened = !!element && !!element.openned;
    if (isHelpCenterOpened) {
      element.close && element.close();
    }
    if (screen === 'help-center') {
      if (element) {
        if (!isHelpCenterOpened) {
          element.open && element.open();
        }
        this.setState({ sidePanel: null });
      } else {
        registerAccountCenterEvent('user-toolbar.error.no-help-center');
        console.error('[sgwt-account-center] Cannot open the Help Center as no Help Center found in the page!');
      }
    } else {
      const sameScreen = this.state.sidePanel === screen;
      registerAccountCenterEvent(`user-toolbar.${screen || 'unknown'}.${sameScreen ? 'close' : 'open'}`);
      this.props.emitEvent(`${sameScreen ? 'hide' : 'show'}-${screen}-card`, {});
      this.setState({ sidePanel: sameScreen ? null : screen });
    }
  };

  hideSidePanel = () => {
    registerAccountCenterEvent(`user-toolbar.${this.state.sidePanel || 'unknown'}.close`);
    if (this.state.sidePanel !== 'help-center') {
      this.props.emitEvent(`hide-${this.state.sidePanel}-card`, {});
    }
    this.setState({ sidePanel: null });
  };

  private disconnect = () => {
    this.hideSidePanel();
    this.props.onSignOut();
  };

  render() {
    // Hide the Account Center when embedded in IOS or Android application / WebView.
    if (this.embeddedInWebView) {
      this.props.logDebug('The Account Center embedded in WebView, so not visible.');
    }

    const widgetMode = whichMode(this.props.mode);
    this.props.logDebug(`The Account Center is running in ${widgetMode === null ? 'no specific' : widgetMode} mode.`);
    const isEmployeePortal = this.props.producerCode === EMPLOYEE_PORTAL_PRODUCER_CODE;

    const user =
      emptyObject(this.props.user) || (this.props.user && !this.props.user.mail)
        ? null
        : this.state.claims
          ? { mail: this.state.claims.sub, name: this.state.claims.name }
          : this.props.user;

    /**
     * Check if the Notification system should be present in Account Center buttons.
     *   - it should not be embedded in a Web view;
     *   - it should be in SG Markets domain or have the mode set to "sg-markets";
     *   - it should have a user connected
     */
    const hasNotif =
      !this.embeddedInWebView &&
      (isInMode(widgetMode, ['sg-markets', 'b2b2c']) || !!this.props.producerCode) &&
      !!user &&
      this.props.authentication === 'sg-connect-v2';
    const hasCustomLinks =
      this.props.showPersonalisation || (this.props.userDropdownLinks && this.props.userDropdownLinks.length > 0);

    return (
      <SgmNotificationProvider
        producerCode={this.props.producerCode}
        user={this.props.user}
        widgetConfiguration={this.widgetConfiguration}
        emitEvent={this.props.emitEvent}
      >
        <div
          className={`d-flex position-relative sgwt-account-center me-3 ${
            this.embeddedInWebView ? 'webview-embedded' : ''
          }`}
        >
          {hasCustomLinks && <CustomLinks {...this.props} />}

          {this.state.helpCenterExists && (
            <HelpCenterButton
              feedbackRequested={this.props.feedbackRequested}
              feedbackRequestIncludeIgnoreOption={this.props.feedbackRequestIncludeIgnoreOption}
              onClick={() => this.displaySidePanel('help-center')}
              onFeedbackClosed={this.props.onFeedbackClosed}
              translator={this.props.translator}
            />
          )}

          {user ? (
            <ConnectedToolbar
              {...this.props}
              availableLanguages={this.props.availableLanguages}
              changePasswordLink={this.authenticationMode ? this.authenticationMode.changePasswordLink : null}
              displaySidePanel={this.displaySidePanel}
              enableNotifications={hasNotif}
              environment={this.environment}
              environmentTag={this.props.environment}
              language={this.props.language}
              onSignOut={this.disconnect}
              platform={this.platform}
              selectUserOnBehalf={() => {
                this.startNavigateAs();
              }}
              sidePanelDisplayed={this.state.sidePanel}
              signOutLink={this.signOutLink}
              user={user}
              widgetMode={widgetMode}
            />
          ) : (
            <DisconnectedToolbar
              {...this.props}
              availableLanguages={this.props.availableLanguages}
              environment={this.environment}
              environmentTag={this.props.environment}
              language={this.props.language}
              widgetMode={widgetMode}
            />
          )}

          <NavigateAsModal
            {...this.props}
            onClose={this.props.hideNavigateAs}
            onNavigateAsSelectUser={(user: IOnBehalfUser) => this.props.navigateAs.onSelectUser(user)}
            show={this.props.showNavigateAsModal}
          />

          {this.state.sidePanel === 'user' ? (
            <UserSidePanel
              {...this.props}
              accountCenterLink={this.defaultAccountCenterLink}
              availableLanguages={this.props.availableLanguages}
              enableNotifications={hasNotif}
              environment={this.environment}
              isEmployeePortal={isEmployeePortal}
              language={this.props.language}
              onClose={this.hideSidePanel}
              onSignOut={this.disconnect}
              selectUserOnBehalf={() => {
                this.startNavigateAs();
              }}
              user={user}
              widgetMode={widgetMode}
              widgetConfiguration={this.widgetConfiguration}
            />
          ) : this.state.sidePanel === 'my-services' ? (
            <MyServicesSidePanel
              {...this.props}
              onClose={this.hideSidePanel}
              environment={this.environment}
              language={this.props.language}
              platform={this.platform}
              authentication={this.props.authentication}
            />
          ) : null}
        </div>
      </SgmNotificationProvider>
    );
  }
}
