import React, { Component } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SearchField } from '../../../../common/components/SearchField';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { IWidgetConfigurationContext, WidgetConfigurationContext } from '../../../../common/configuration';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { isInMode, whichMode } from '../../../../common/sgwt-widgets-utils';
import { MY_SERVICES_ADD_SERVICE_FORM } from '../../shared/sgwt-account-center.endpoints';
import { ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';
import { getBookmarks, getMyEssentials, getStories, searchServices } from './my-services.api';
import {
  IBookmarkedService,
  IBookmarksResponse,
  IEssentialServicesResponse,
  IMyServiceSearchResults,
  IServiceVisibility,
  ISgmService,
  IStory,
} from './my-services.types';
import { MyServicesSearchPanel } from './MyServicesSearchPanel';
import { ServiceCard } from './ServiceCard';
import { Stories } from './Stories';

const MAX_ESSENTIALS_SERVICES_TO_DISPLAY = 8;

interface IMyServicesPanelProps extends ITranslatorProps {
  authentication?: string;
  language: string;
  environment: 'homologation' | 'production';
  logDebug: (...messages: any[]) => void;
  mode?: string;
  onClose: () => void;
  user: ISgwtAccountCenterUser | null;
}

interface IMyServicesPanelState {
  accessWarning: boolean;
  accessWarningMessage: string | null;
  bookmarkedServices: IBookmarkedService[];
  bookmarksHaveChanged: boolean;
  essentialServices: ISgmService[] | null;
  loadingFullData: boolean;
  loadingSearchedData: boolean;
  searchedServices: IMyServiceSearchResults | null;
  searchValue: string;
  stories: IStory[];
}

export class MyServicesPanel extends Component<IMyServicesPanelProps, IMyServicesPanelState> {
  static contextType = WidgetConfigurationContext;
  private widgetConfiguration: WidgetConfiguration;

  constructor(props: IMyServicesPanelProps, context: IWidgetConfigurationContext) {
    super(props);
    this.widgetConfiguration = context!.widgetConfiguration;
    this.state = {
      accessWarning: false,
      accessWarningMessage: null,
      bookmarkedServices: [],
      bookmarksHaveChanged: false,
      essentialServices: null,
      loadingFullData: true,
      loadingSearchedData: false,
      searchedServices: null,
      searchValue: '',
      stories: [],
    };
  }

  // Call API for the "home panel", i.e. Essentials, Bookmarks and Stories
  loadEssentials(loadStories: boolean) {
    registerAccountCenterEvent('my-services.load-services');
    const { environment, language } = this.props;

    // Load Essentials
    getMyEssentials(this.widgetConfiguration, environment, language)
      .then((data: IEssentialServicesResponse) => {
        this.props.logDebug('[my-services:api] Data received:', data);
        this.setState({
          loadingFullData: false,
          loadingSearchedData: false,
          essentialServices: data.Items,
        });
      })
      .catch((err: Error) => {
        this.props.logDebug('[my-services:api] Error while fetching the Essentials services', err);
        registerAccountCenterEvent('my-services.error.load-essentials', err.message);
        this.setState({
          loadingFullData: false,
          loadingSearchedData: false,
          bookmarksHaveChanged: false,
        });
      });

    // Load bookmarks
    getBookmarks(this.widgetConfiguration, environment)
      .then((data: IBookmarksResponse) =>
        this.setState({
          bookmarkedServices: data.Items,
        }),
      )
      .catch((err: Error) => {
        this.props.logDebug('[my-services:api] Error while fetching the user bookmarks', err);
        registerAccountCenterEvent('my-services.error.bookmarks.load', err.message);
      });

    if (loadStories) {
      // Load stories
      registerAccountCenterEvent('my-services.load-stories');
      getStories(this.widgetConfiguration, environment)
        .then((data: IStory[]) => {
          this.setState({ stories: data });
        })
        .catch((err: Error) => {
          this.props.logDebug('[my-services:api] Error while fetching the Stories service', err);
          registerAccountCenterEvent('my-services.error.load-stories', err.message);
        });
    }
  }

  // Call API for a search
  loadSearchedServices() {
    const search = this.state.searchValue;
    registerAccountCenterEvent('my-services.search', search);
    const { environment, language } = this.props;
    searchServices(this.widgetConfiguration, environment, language, search)
      .then((data: IMyServiceSearchResults) => {
        // Depending on the user typing speed, a request R1 can be send before another request R2
        // but the response of R1 may come after the one from R2. In that case, we ignore the old
        // responses, and only keeps the response made for the current search value.
        const forLastSearch = search === this.state.searchValue;
        this.props.logDebug(`[my-services:api] Data received${forLastSearch ? '' : '(not last request)'}:`, data);
        if (forLastSearch) {
          this.setState({
            loadingFullData: false,
            loadingSearchedData: false,
            searchedServices: data,
          });
        }
      })
      .catch((err: Error) => {
        this.props.logDebug('[my-services:api] Error while fetching the data from search', err);
        registerAccountCenterEvent('my-services.error.search', err.message);
        this.setState({
          loadingFullData: false,
          loadingSearchedData: false,
        });
      });
  }

  loadData() {
    const fromSearch = this.state.searchValue !== '';
    this.setState({
      loadingFullData: !fromSearch,
      loadingSearchedData: fromSearch,
    });
    if (fromSearch) {
      this.loadSearchedServices();
    } else {
      const b2b2c = isInMode(whichMode(this.props.mode), ['b2b2c']);
      this.loadEssentials(!b2b2c);
    }
  }

  componentDidMount() {
    this.loadData();
  }

  switchWarningDisplay(visibility?: IServiceVisibility | null) {
    const message = visibility
      ? visibility[this.props.language]
        ? visibility[this.props.language]
        : visibility.en
      : null;
    this.setState(
      {
        accessWarning: !this.state.accessWarning,
        accessWarningMessage: message,
      },
      () => {
        if (this.state.accessWarning) {
          registerAccountCenterEvent('my-services.access-refused', message);
        }
      },
    );
  }

  search(value: string) {
    if (this.state.searchValue === value) {
      return;
    }
    const searchCleared = value === '' && this.state.searchValue !== '';
    this.setState(
      {
        searchValue: value,
      },
      () => {
        if (!searchCleared) {
          this.loadData();
        } else if (this.state.bookmarksHaveChanged) {
          // Since the bookmarks have changed, the list of items in Essentials & Bookmarks have changed,
          // so we reload them...
          this.loadEssentials(false);
        }
      },
    );
  }

  bookmarkUpdated = () => this.setState({ bookmarksHaveChanged: true });

  render() {
    const { translator } = this.props;
    const formUrl = MY_SERVICES_ADD_SERVICE_FORM[this.props.environment]; // TODO Hide when outside? Not SG collaborator?

    let searchField = null;
    if (!this.state.loadingFullData && !this.state.accessWarning) {
      searchField = (
        <div className="pb-3 pt-0">
          <SearchField
            onSearch={(value: string) => {
              this.search(value);
            }}
            searchValue={this.state.searchValue}
            placeholder={translator.translate('myServices.placeholder')}
          />
        </div>
      );
    }

    const accessWarningMessage = (
      <div className="d-flex h-100">
        <div className="align-self-center">
          <i className="sgwt-widgets-icon text-warning icon-xl">
            <SvgIcon type="device_hub" />
          </i>
          <div className="display-5 my-3">{translator.translate('internalNetworkRequired')}</div>
          <div>{this.state.accessWarningMessage || translator.translate('internalNetworkRequiredDescription')}</div>
          <button
            className="btn sgbs-btn-default btn-default btn-block mt-4"
            onClick={() => this.switchWarningDisplay()}
          >
            {translator.translate('back')}
          </button>
        </div>
      </div>
    );

    return (
      <div className="sgwt-my-services h-100">
        {searchField}

        {this.state.accessWarning ? (
          accessWarningMessage
        ) : this.state.loadingFullData ? (
          <div className="spinner-grow blinker blinker-lg text-nowrap mb-5">{translator.translate('loading')}</div>
        ) : this.state.searchValue === '' ? (
          <div>
            <h3 className="h6 mb-2">{translator.translate('myServices.essentialsServices')}</h3>
            <div className="container g-12px d-block">
              {!!this.state.essentialServices && this.state.essentialServices.length > 0 ? (
                <div className="row">
                  {this.state.essentialServices
                    .slice(0, MAX_ESSENTIALS_SERVICES_TO_DISPLAY)
                    .map((service: ISgmService) => (
                      <ServiceCard
                        key={`my-service-${service.ServiceKey}`}
                        isSgmService={true}
                        service={service}
                        onClick={() => this.switchWarningDisplay(service.Visibility)}
                      />
                    ))}
                </div>
              ) : (
                <span className="row text-secondary mb-5">{translator.translate('myServices.notFound')}</span>
              )}
            </div>
            {this.state.bookmarkedServices.length > 0 && (
              <div>
                <h3 className="h6 my-2">{translator.translate('myServices.bookmarks')}</h3>
                <div className="container g-12px d-block">
                  <div className="row">
                    {this.state.bookmarkedServices.map((service: IBookmarkedService) => (
                      <ServiceCard
                        isSgmService={service.Origin === 'SgmService'}
                        service={service}
                        key={`bookmarked-service-${service.Id}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {this.state.stories && this.state.stories.length > 0 && (
              <Stories
                environment={this.props.environment}
                language={this.props.language}
                stories={this.state.stories}
                translator={this.props.translator}
              />
            )}
          </div>
        ) : this.state.loadingSearchedData ? (
          <div className="spinner-grow blinker blinker-lg text-nowrap mb-5">{translator.translate('loading')}</div>
        ) : (
          <MyServicesSearchPanel
            bookmarkedServices={this.state.bookmarkedServices}
            bookmarkUpdated={this.bookmarkUpdated}
            environment={this.props.environment}
            formUrl={formUrl}
            language={this.props.language}
            onClick={(visibility) => this.switchWarningDisplay(visibility)}
            searchResults={this.state.searchedServices}
            searchValue={this.state.searchValue}
            translator={this.props.translator}
            user={this.props.user}
            widgetConfiguration={this.widgetConfiguration}
          />
        )}
      </div>
    );
  }
}
