import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { SgBootstrapThemeExtended } from '../../../../common/constants';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import {
  addAuthenticationInfoInRequestHeader,
  checkResponseStatus,
  isNotInMode,
  modeSGMarketsEnabled,
  WidgetsMode,
} from '../../../../common/sgwt-widgets-utils';
import { SG_MARKETS_LANGUAGE_API } from '../../shared/sgwt-account-center.endpoints';
import { INavigateAs, ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';

interface AccountSectionProps extends ITranslatorProps {
  accountCenterLink: string;
  availableLanguages: string[];
  isEmployeePortal: boolean;
  environment: 'homologation' | 'production';
  language: string;
  navigateAs: INavigateAs;
  onLanguageChanged: (lang: string) => void;
  onSignOut: () => void;
  onThemeChanged: (newTheme: SgBootstrapThemeExtended) => void;
  selectUserOnBehalf: () => void;
  theme: SgBootstrapThemeExtended | null;
  user: ISgwtAccountCenterUser;
  widgetMode: WidgetsMode | null;
  widgetConfiguration: WidgetConfiguration;
}

interface DropdownItem {
  label: string;
  id: string;
  active: boolean;
}

interface DropdownProps {
  header?: ReactNode;
  element: HTMLLIElement | null;
  items: DropdownItem[];
  onSelect: (item: DropdownItem) => void;
  onClose: () => void;
}

const Dropdown = ({ header, element, items, onSelect, onClose }: DropdownProps) => {
  useEffect(() => {
    const checkTheClick = (event: Event) => {
      const target = event.target as Node;
      if (element && !element.contains(target)) {
        onClose();
      }
    };
    window.addEventListener('click', checkTheClick, false);

    return () => window.removeEventListener('click', checkTheClick);
  }, [element, onClose]);

  // We force the font-family to `Inter, sans-serif` to avoid dynamically loading
  // the Japanese font (Noto) which is quite heavy... #767.
  return (
    <div className="dropdown-menu show w-100 position-absolute m-0">
      {header}
      {items.map((item: DropdownItem) => (
        <button
          key={item.id}
          className={`dropdown-item ${item.active ? 'active' : ''}`}
          onClick={() => onSelect(item)}
          style={{ fontFamily: 'Inter, sans-serif' }}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

const UserSection = ({ user, onSignOut, translator }: AccountSectionProps) => {
  const clickSignOut = () => {
    registerAccountCenterEvent('my-account.click-sign-out');
    onSignOut();
  };

  return (
    <section className="d-flex justify-content-between align-items-center p-3">
      <div className="text-primary">
        <h5 className="text-capitalize pb-1 mb-0">{user.name}</h5>
        <p className="mb-0">{user.mail}</p>
      </div>
      <button
        className="btn btn-flat-secondary btn-md d-flex flex-column align-items-center sgwt-account-center-user-panel-sign-out-button"
        onClick={clickSignOut}
      >
        <SvgIcon type="power_settings_new" width={18} height={18} />
        {translator.translate('signOut')}
      </button>
    </section>
  );
};

const MultipleLanguages = ({
  availableLanguages,
  environment,
  language,
  onLanguageChanged,
  translator,
  widgetConfiguration,
  widgetMode,
}: AccountSectionProps) => {
  const element = useRef<HTMLLIElement | null>(null);
  const [showSelection, setShowSelection] = useState(false);
  const isSGM = modeSGMarketsEnabled(widgetMode);

  const dropdownHeader = isSGM ? (
    <div className="dropdown-header">{translator.translate('language.sgm')}</div>
  ) : undefined;

  const items: DropdownItem[] = availableLanguages.map((lang) => ({
    id: lang,
    label: `${lang.toUpperCase()} - ${translator.getLanguageName(lang)}`,
    active: language === lang,
  }));

  const changeLanguage = ({ id }: DropdownItem) => {
    setShowSelection(false);
    registerAccountCenterEvent('languages-dropdown.change-language', id);
    onLanguageChanged(id);
    if (isSGM) {
      // Call SG Markets endpoint to update the language globally.
      registerAccountCenterEvent('languages-dropdown.change-language.sg-markets', id);
      const url = SG_MARKETS_LANGUAGE_API[environment] + id.toLowerCase();
      const options = addAuthenticationInfoInRequestHeader(
        {
          headers: {
            accept: 'application/json',
          },
          mode: 'cors',
          method: 'PUT',
        },
        widgetConfiguration,
        'sg-connect-v2',
      );
      fetch(url, options)
        .then(checkResponseStatus)
        .catch((err) => {
          console.error(`Failed to change the default SG Markets language to "${id}"`, err);
          registerAccountCenterEvent('languages-dropdown.change-language.sg-markets.error', id);
        });
    }
  };

  return (
    <>
      <li ref={element} className="list-group-item dropdown border-top border-opacity-40 p-0">
        <button
          className="btn btn-flat-primary btn-block d-flex justify-content-between align-items-center px-3 py-2 sgwt-account-center-user-panel-language-button"
          onClick={() => setShowSelection(!showSelection)}
        >
          <div className="d-flex align-items-center my-1">
            <SvgIcon type="language" className="text-secondary" width={18} height={18} />
            <span className="text-secondary fw-medium mx-2">{translator.translate('language')}</span>
            <span className="badge badge-discreet-primary rounded-pill">{availableLanguages.length}</span>
          </div>
          <div className="d-flex align-items-center my-1">
            <span className="me-2 mr-2">
              <span className="text-uppercase">{language}</span> - {translator.getLanguageName(language)}
            </span>
            <SvgIcon type="arrow_drop_down" width={18} height={18} />
          </div>
        </button>
        {showSelection && (
          <Dropdown
            element={element ? element.current : null}
            items={items}
            onClose={() => setShowSelection(false)}
            onSelect={changeLanguage}
            header={dropdownHeader}
          />
        )}
      </li>
    </>
  );
};

const OneLanguage = ({ language, translator }: AccountSectionProps) => {
  const [hovering, setHovering] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    let timeoutId: number;
    if (hovering) {
      timeoutId = window.setTimeout(() => {
        setShowTooltip(true);
        registerAccountCenterEvent('languages.show-single-language-hint');
      }, 700);
    }
    return () => {
      if (timeoutId) {
        window.clearInterval(timeoutId);
      }
      setShowTooltip(false);
    };
  }, [hovering]);

  return (
    <li
      className="list-group-item dropdown border-top border-opacity-40 p-0"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <div className="btn btn-flat-primary btn-block d-flex justify-content-between align-items-center px-3 py-2 sgwt-account-center-user-panel-language-button">
        <div className="d-flex align-items-center my-1">
          <SvgIcon type="language" className="text-secondary" width={18} height={18} />
          <span className="text-secondary fw-medium mx-2">{translator.translate('language')}</span>
        </div>
        <div className="d-flex align-items-center my-1">
          {showTooltip && (
            <div
              className="popover bs-popover-left bs-popover-start fade show position-absolute bg-alt-lvl1 sgwt-account-center-unique-language-popover"
              role="tooltip"
              data-popper-placement="left"
            >
              <div className="popover-arrow arrow position-absolute" style={{ top: '33%' }}></div>
              <div className="popover-body text-primary-alt">{translator.translate('language.single')}</div>
            </div>
          )}
          <span className="me-2 mr-2">
            <span className="text-uppercase">{language}</span> - {translator.getLanguageName(language)}
          </span>
          <SvgIcon type="arrow_drop_down" width={18} height={18} />
        </div>
      </div>
    </li>
  );
};

const ThemeSection = ({ onThemeChanged, theme, translator }: AccountSectionProps) => {
  const element = useRef<HTMLLIElement | null>(null);
  const [showSelection, setShowSelection] = useState(false);

  if (!theme) {
    return null;
  }

  const choices: DropdownItem[] = ['standard', 'dark', 'system'].map((choice: string) => ({
    id: choice,
    label: translator.translate(`theme.${choice}`),
    active: theme === choice,
  }));

  const changeTheme = (selection: DropdownItem) => {
    const newTheme = selection.id as SgBootstrapThemeExtended;
    registerAccountCenterEvent('my-account.change-theme', newTheme);
    onThemeChanged(newTheme);
    setShowSelection(false);
  };

  return (
    <>
      <li ref={element} className="list-group-item dropdown border-top border-opacity-40 p-0">
        <button
          className="btn btn-flat-primary btn-block d-flex justify-content-between align-items-center px-3 py-2 sgwt-account-center-user-panel-theme-button"
          onClick={() => setShowSelection(!showSelection)}
        >
          <div className="d-flex align-items-center my-1">
            <SvgIcon type="brightness_4" className="text-secondary" width={18} height={18} />
            <span className="text-secondary fw-medium ms-2 ml-2">{translator.translate('theme')}</span>
          </div>
          <div className="d-flex align-items-center my-1">
            <span className="me-2 mr-2">{translator.translate(`theme.${theme}`)}</span>
            <SvgIcon type="arrow_drop_down" width={18} height={18} />
          </div>
        </button>
        {showSelection && (
          <Dropdown
            element={element ? element.current : null}
            items={choices}
            onClose={() => setShowSelection(false)}
            onSelect={changeTheme}
          />
        )}
      </li>
    </>
  );
};

const ViewAsSection = ({ navigateAs, selectUserOnBehalf, translator }: AccountSectionProps) => {
  if (!navigateAs.active) {
    return null;
  }
  const clickNavigateAs = () => {
    registerAccountCenterEvent('my-account.click-navigate-as');
    selectUserOnBehalf();
  };
  return (
    <li className="list-group-item border-top border-opacity-40 p-0">
      <button
        className="btn btn-flat-primary btn-block d-flex justify-content-between align-items-center px-3 py-2 sgwt-account-center-user-panel-navigate-as-button"
        onClick={clickNavigateAs}
      >
        <div className="d-flex align-items-center my-1">
          <SvgIcon type="supervisor_account" className="text-secondary" width={18} height={18} />
          <span className="text-secondary fw-medium ms-2 me-2">{translator.translate('viewAs')}</span>
        </div>
        <div className="d-flex align-items-center my-1">
          <span className="me-2 mr-2">{translator.translate('viewAs.selectUser')}</span>
          <SvgIcon type="arrow_forward" width={18} height={18} />
        </div>
      </button>
    </li>
  );
};

export const AccountSection = (props: AccountSectionProps) => {
  const trackManageAccount = () => registerAccountCenterEvent('my-account.click-manage-account');
  const hideManageAccountLink = isNotInMode(props.widgetMode, ['sg-markets', 'b2b2c']) || props.isEmployeePortal;

  return (
    <div className="px-4 pb-4">
      <section className="bg-lvl2">
        <UserSection {...props} />

        <ul className="list-group">
          {props.availableLanguages.length > 1 ? <MultipleLanguages {...props} /> : <OneLanguage {...props} />}
          <ThemeSection {...props} />
          <ViewAsSection {...props} />
        </ul>

        {!hideManageAccountLink && (
          <section className="bg-lvl3">
            <a
              href={props.accountCenterLink}
              className="btn btn-flat btn-block btn-lg btn-icon-start btn-icon-text sgwt-account-center-user-panel-manage-account-button"
              target="_blank"
              onClick={trackManageAccount}
              rel="noreferrer"
            >
              <SvgIcon className="me-2 mr-2" type="settings" width={18} height={18} />
              {props.translator.translate('accountCenter')}
            </a>
          </section>
        )}
      </section>
    </div>
  );
};
