import {
  HubConnection,
  HubConnectionBuilder,
  IHttpConnectionOptions,
  LogLevel,
  RetryContext,
} from '@microsoft/signalr';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { getAuthorizationToken } from '../../../../common/sgwt-widgets-utils';
import { IPartialIncident } from '../PartialIncident/PartialIncident';
import {
  DELAY_RETRY,
  MAX_RETRY,
  MAX_VIEW,
  NOTIFICATION_PATH,
  SgmNotification,
  SgmNotificationUniverse,
} from './notification.types';

function checkStatus(response: Response): Response {
  if (response.ok) {
    return response;
  }
  throw new Error(response.statusText);
}

function parseJson<T>(response: Response): Promise<T> {
  return response.json();
}

const getEnvironment = (widgetConfiguration: WidgetConfiguration): 'homologation' | 'production' =>
  widgetConfiguration.environment.toLowerCase() === 'production' ? 'production' : 'homologation';

const getApiBase = (widgetConfiguration: WidgetConfiguration): string => {
  return NOTIFICATION_PATH[getEnvironment(widgetConfiguration)].api;
};

export async function getPartialIncidents(widgetConfiguration: WidgetConfiguration, producerCode: string) {
  return fetch(`${getApiBase(widgetConfiguration)}/partialincidents?producerCode=${producerCode}`, {
    headers: {
      accept: 'application/json',
      authorization: getAuthorizationToken(widgetConfiguration) || '',
    },
    method: 'GET',
    mode: 'cors',
  })
    .then(checkStatus)
    .then((response) => parseJson<IPartialIncident[]>(response))
    .catch((err) => {
      registerAccountCenterEvent('partial-incidents.error.load-data', err.message);
      widgetConfiguration.error(err.toString());
      return [];
    });
}

export async function getNotifications(widgetConfiguration: WidgetConfiguration): Promise<SgmNotification[]> {
  return fetch(`${getApiBase(widgetConfiguration)}/notifications/history?limit=${MAX_VIEW}`, {
    headers: {
      accept: 'application/json',
      authorization: getAuthorizationToken(widgetConfiguration) || '',
    },
    method: 'GET',
    mode: 'cors',
  })
    .then(checkStatus)
    .then((response) => parseJson<SgmNotification[]>(response))
    .catch((err) => {
      registerAccountCenterEvent('notifications.error.load-data', err.message);
      widgetConfiguration.error(err.toString());
      return [];
    });
}

export function markNotificationsAsRead(widgetConfiguration: WidgetConfiguration, notificationIds: number[]) {
  const data = {
    notificationIds,
    allForRequester: false, // Could be used to mark ALL notifications as read...
  };
  fetch(`${getApiBase(widgetConfiguration)}/notifications/do-mark-as-read`, {
    body: JSON.stringify(data),
    headers: {
      accept: 'application/json',
      authorization: getAuthorizationToken(widgetConfiguration) || '',
      'content-type': 'application/json',
    },
    method: 'PUT',
    mode: 'cors',
  })
    .then(checkStatus)
    .catch((err) => widgetConfiguration.error(err.toString()));
}

const ONE_MINUTE = 60 * 1000;

export function createConnection(widgetConfiguration: WidgetConfiguration, options: IHttpConnectionOptions) {
  const url = NOTIFICATION_PATH[getEnvironment(widgetConfiguration)].negotiation;
  return new HubConnectionBuilder()
    .withUrl(url, options)
    .configureLogging(getEnvironment(widgetConfiguration) === 'production' ? LogLevel.Error : LogLevel.Information)
    .withAutomaticReconnect({
      nextRetryDelayInMilliseconds: (retryContext: RetryContext) => {
        widgetConfiguration.log(
          `Attempt to auto-reconnect. Retry count: ${retryContext.previousRetryCount}. Time spend reconnecting in ms: ${retryContext.elapsedMilliseconds}. Reason: ${retryContext.retryReason.stack}`,
        );
        // Verify that we have a token. If not, we stop the reconnections attempts.
        const currentToken = getAuthorizationToken(widgetConfiguration);
        if (!currentToken) {
          widgetConfiguration.log('No SG Connect token found, reconnections aborted.');
          return null;
        }
        // During the fist 5 minutes, we wait up to 5 minutes until we try to reconnect.
        // It integrates with some random calculations to avoid too many reconnections at the same time.
        if (retryContext.elapsedMilliseconds < 5 * ONE_MINUTE) {
          return Math.floor(Math.random() * 5 * ONE_MINUTE);
        }
        // After the 5 first minutes, we increase the time before the next retry...
        if (retryContext.elapsedMilliseconds < 20 * ONE_MINUTE) {
          return Math.floor(Math.random() * 15 * ONE_MINUTE);
        }
        // After 20 minutes, we give up.
        return null;
      },
    })
    .build();
}

export async function startHubConnection(
  widgetConfiguration: WidgetConfiguration,
  connection: HubConnection,
  tryCount = 0,
) {
  try {
    await connection.start();
    widgetConfiguration.log('HubConnection - connected');
  } catch (err: any) {
    widgetConfiguration.warn(`HubConnection - attempt ${tryCount + 1} failed - ${err.toString()}`);
    if (tryCount < MAX_RETRY) {
      setTimeout(
        () => startHubConnection(widgetConfiguration, connection, tryCount + 1),
        tryCount * DELAY_RETRY * 1000,
      );
    } else {
      const url = NOTIFICATION_PATH[getEnvironment(widgetConfiguration)].negotiation;
      widgetConfiguration.error(`HubConnection - Exceed maximum retry attempt (${MAX_RETRY}): ${url}`);
    }
  }
}

export async function joinGroup(
  widgetConfiguration: WidgetConfiguration,
  producerCode: string,
  connection: HubConnection,
) {
  const url = NOTIFICATION_PATH[getEnvironment(widgetConfiguration)].hubRegistration;
  return fetch(`${url}/do-join-producer-groups?producerCode=${producerCode}&connectionId=${connection.connectionId}`, {
    headers: {
      accept: 'application/json',
      authorization: getAuthorizationToken(widgetConfiguration) || '',
    },
    method: 'PUT',
    mode: 'cors',
  })
    .then(checkStatus)
    .catch((err) => registerAccountCenterEvent('notifications.error.join-group', err.message));
}

export async function leaveGroup(
  widgetConfiguration: WidgetConfiguration,
  producerCode: string,
  connection: HubConnection,
) {
  const url = NOTIFICATION_PATH[getEnvironment(widgetConfiguration)].hubRegistration;
  return fetch(`${url}/do-leave-producer-groups?producerCode=${producerCode}&connectionId=${connection.connectionId}`, {
    headers: {
      accept: 'application/json',
      authorization: getAuthorizationToken(widgetConfiguration) || '',
    },
    method: 'DELETE',
    mode: 'cors',
  })
    .then(checkStatus)
    .catch((err) => registerAccountCenterEvent('notifications.error.leave-group', err.message));
}

const sortByCreatedDate = function (a: SgmNotification, b: SgmNotification) {
  const dateA = new Date(a.createdDate);
  const dateB = new Date(b.createdDate);
  return dateB.toISOString().localeCompare(dateA.toISOString());
};

export type GroupedNotifications = Array<SgmNotification[]>;
export type DistributedNotifications = Record<SgmNotificationUniverse, GroupedNotifications>;

/**
 * To ease the display of notifications, we distribute them by universe and by Service/Category:
 * {
 *   sgMarkets: [         <-- all notifications - if any - for SG Markets services
 *     [notif1],
 *     [notif2, notif3],  <-- notifications are regrouped when they share the same Service & Category.
 *     [notif4]
 *   ],
 *   societegenerale: [   <-- all notifications - if any - for Societe Generale Group
 *     [notif5],
 *     [notif6]
 *   ],
 * }
 */
export function distributeNotifications(allNotifications: SgmNotification[]): DistributedNotifications {
  const distributedNotifications: DistributedNotifications = {
    sgMarkets: [],
    societeGenerale: [],
  };

  const sortedByDate = [...allNotifications].sort(sortByCreatedDate);
  for (const notif of sortedByDate) {
    const { category, producer } = notif;
    // In case we have an unknown universe (e.g. NOT `sgMarkets` nor `societeGenerale`) we use `sgMarkets` by default.
    const universe = producer.universe === 'societeGenerale' ? 'societeGenerale' : 'sgMarkets';
    if (producer.code && category) {
      // This notification is associated to a Service. We check if we already have a notificatio
      const index = distributedNotifications[universe].findIndex(
        (ns: SgmNotification[]) => ns[0].producer.code === producer.code && ns[0].category === category,
      );
      if (index !== -1) {
        distributedNotifications[universe][index].push(notif);
      } else {
        distributedNotifications[universe].push([notif]);
      }
    } else {
      // This notification is not associated to a Service.
      distributedNotifications[universe].push([notif]);
    }
  }

  return distributedNotifications;
}

/**
 * Regroup all notifications of the current service.
 */
export function getServiceNotifications(allNotifications: SgmNotification[], service?: string): GroupedNotifications {
  if (!service) {
    return [];
  }
  const filteredNotifications = allNotifications.filter((n) => n.producer.code === service).sort(sortByCreatedDate);
  const groups: GroupedNotifications = [];
  for (const notif of filteredNotifications) {
    const index = groups.findIndex((ns: SgmNotification[]) => ns[0].category === notif.category);
    if (index !== -1) {
      groups[index].push(notif);
    } else {
      groups.push([notif]);
    }
  }
  return groups;
}
