import React from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { SidePanel, SidePanelHeader } from '../../../../common/components/SidePanel';
import { SgBootstrapThemeExtended } from '../../../../common/constants';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';
import { isInMode, whichMode, WidgetsMode } from '../../../../common/sgwt-widgets-utils';
import { MY_SERVICES_LINKS } from '../../shared/sgwt-account-center.endpoints';
import { INavigateAs, ISgwtAccountCenterUser } from '../../shared/sgwt-account-center.types';
import { MyServicesPanel } from '../MyServices/MyServicesPanel';
import { NOTIFICATION_PATH } from '../Notification/notification.types';
import { AccountSection } from './AccountSection';
import { NotificationsSection } from './NotificationsSection';

interface AccountCenterSidePanelProps extends ITranslatorProps {
  onClose: () => void;
}

interface UserPanelProps extends AccountCenterSidePanelProps {
  accountCenterLink: string;
  availableLanguages: string[];
  enableNotifications: boolean;
  environment: 'homologation' | 'production';
  isEmployeePortal: boolean;
  language: string;
  navigateAs: INavigateAs;
  onLanguageChanged: (lang: string) => void;
  onSignOut: () => void;
  onThemeChanged: (newTheme: SgBootstrapThemeExtended) => void;
  producerCode?: string;
  selectUserOnBehalf: () => void;
  theme: SgBootstrapThemeExtended | null;
  user: ISgwtAccountCenterUser | null;
  widgetConfiguration: WidgetConfiguration;
  widgetMode: WidgetsMode | null;
}

interface IMyServicesPanelProps extends AccountCenterSidePanelProps {
  authentication?: string;
  language: string;
  environment: 'homologation' | 'production';
  logDebug: (...messages: any[]) => void;
  mode?: string;
  platform: 'desktop' | 'mobile' | 'ios' | 'android';
  user: ISgwtAccountCenterUser | null;
}

export const UserSidePanel = (props: UserPanelProps) => {
  const viewAllLink =
    NOTIFICATION_PATH[props.environment].viewAll + (props.producerCode ? `?producerCode=${props.producerCode}` : '');
  const trackViewAll = () => registerAccountCenterEvent('notifications.click-view-all');

  return (
    <SidePanel
      widget="sgwt-account-center"
      onClose={props.onClose}
      title={props.translator.translate('panel.my-account')}
    >
      <SidePanelHeader
        translator={props.translator}
        hasPreviousButton={false}
        onClose={props.onClose}
        title={props.translator.translate('myAccount')}
      />
      <div className="card-body px-0 py-0 mb-1 d-flex flex-column" style={{ overflowY: 'auto' }}>
        <AccountSection {...props} user={props.user!} accountCenterLink={props.accountCenterLink} />

        {props.enableNotifications && <NotificationsSection {...props} />}
      </div>
      {props.enableNotifications && (
        <div className="card-footer px-4 pb-3">
          <a
            href={viewAllLink}
            target="_blank"
            onClick={trackViewAll}
            rel="noreferrer"
            className="btn btn-link btn-block btn-lg"
          >
            {props.translator.translate('notifications.seeMore')}
          </a>
        </div>
      )}
    </SidePanel>
  );
};

export const MyServicesSidePanel = (props: IMyServicesPanelProps) => {
  const clickViewMyServices = () => registerAccountCenterEvent('my-services.click-view-my-services');
  const clickDiscoverMarketplace = () => registerAccountCenterEvent('my-services.click-discover-marketplace');
  const b2b2c = isInMode(whichMode(props.mode), ['b2b2c']);

  return (
    <SidePanel
      widget="sgwt-account-center"
      onClose={props.onClose}
      title={props.translator.translate('panel.my-services')}
    >
      <SidePanelHeader
        translator={props.translator}
        hasPreviousButton={false}
        onClose={props.onClose}
        title={props.translator.translate('myServices.title')}
      />
      <div className="card-body px-4 py-0 mb-4" style={{ overflowY: 'auto' }}>
        <MyServicesPanel {...props} />
      </div>
      <div className="card-footer px-4 pb-3">
        <a
          href={MY_SERVICES_LINKS[props.environment]}
          target="_blank"
          className="btn btn-primary btn-block btn-lg mt-2"
          onClick={clickViewMyServices}
          rel="noreferrer"
        >
          {props.translator.translate('myServices.links.myServices')}
        </a>
        {!b2b2c && (
          <a
            rel="noopener noreferrer"
            href="https://info.sgmarkets.com"
            target="_blank"
            className="btn btn-light btn-block btn-lg mt-2"
            onClick={clickDiscoverMarketplace}
          >
            {props.translator.translate('myServices.links.marketplace')}
          </a>
        )}
      </div>
    </SidePanel>
  );
};
