import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import { BasicModal, BasicModalBody, BasicModalFooter } from '../../../../common/components/BasicModal';
import { SvgIcon } from '../../../../common/components/SvgIcon';
import { registerAccountCenterEvent } from '../../../../common/monitoring';
import { ITranslatorProps } from '../../../../common/sgwt-i18n';

const locales: any = {
  de: require('date-fns/locale/de'),
  en: require('date-fns/locale/en'),
  es: require('date-fns/locale/es'),
  fr: require('date-fns/locale/fr'),
  ja: require('date-fns/locale/ja'),
  jp: require('date-fns/locale/ja'),
  ru: require('date-fns/locale/ru'),
  zh: require('date-fns/locale/zh_cn'),
};

export type PartialIncidentStatus = 'open' | 'ongoing' | 'closed';
type PartialIncidentTargetingType = 'specific' | 'broadcast';

export interface IPartialIncident {
  createdDate: string;
  id: number;
  producerCode: string;
  status: PartialIncidentStatus;
  targets: string;
  targetingType: PartialIncidentTargetingType;
  text: string;
}

export const PARTIAL_INCIDENT_OPEN: PartialIncidentStatus = 'open';
export const PARTIAL_INCIDENT_CLOSED: PartialIncidentStatus = 'closed';
export const PARTIAL_INCIDENT_ONGOING: PartialIncidentStatus = 'ongoing';

const getGlobalStatus = (incidents: IPartialIncident | IPartialIncident[]): PartialIncidentStatus => {
  if (Array.isArray(incidents)) {
    for (const incident of incidents) {
      if (incident.status !== PARTIAL_INCIDENT_CLOSED) {
        return PARTIAL_INCIDENT_OPEN;
      }
    }
    return PARTIAL_INCIDENT_CLOSED;
  }
  return incidents.status;
};

const getStyleClass = (status: PartialIncidentStatus, isBadge = false): string => {
  if (status === PARTIAL_INCIDENT_CLOSED) {
    return 'success';
  }
  if (isBadge && status === PARTIAL_INCIDENT_ONGOING) {
    return 'secondary';
  }
  return 'warning';
};

const sortByCreatedDate = function (a: IPartialIncident, b: IPartialIncident) {
  const dateA = new Date(a.createdDate);
  const dateB = new Date(b.createdDate);
  return dateB.getTime() - dateA.getTime();
};

interface ItemProps extends ITranslatorProps {
  incident: IPartialIncident;
  index: number;
  language: string;
  viewed: boolean;
}

const PartialIncidentItem = ({ incident, viewed, index, language, translator }: ItemProps) => {
  const status = incident.status === PARTIAL_INCIDENT_OPEN && viewed ? PARTIAL_INCIDENT_ONGOING : incident.status;
  return (
    <div className={index === 0 ? '' : 'border-top pt-3'}>
      <div className={`badge badge-outline-${getStyleClass(status, true)} text-uppercase badge-prepend-square mb-2`}>
        {status === PARTIAL_INCIDENT_CLOSED
          ? translator.translate('incidents.resolved')
          : status === PARTIAL_INCIDENT_OPEN
            ? translator.translate('incidents.newIncident')
            : status}
      </div>
      <div className="d-flex align-items-center justify-content-between text-large font-weight-medium mb-3">
        <span>{incident.text}</span>
        <span className="text-secondary text-nowrap ml-3 ms-3">
          {distanceInWordsToNow(incident.createdDate, {
            locale: locales[language],
          })}
        </span>
      </div>
    </div>
  );
};

interface PartialIncidentProps extends ITranslatorProps {
  incidents: IPartialIncident[];
  incidentStatus: number | null;
  language: string;
  cleanIncidents: () => void;
}

interface IncidentModalProps extends ITranslatorProps {
  incidents: IPartialIncident[];
  incidentViewed: number[];
  language: string;
  onClose: () => void;
}

const IncidentModal = ({ incidents, incidentViewed, language, onClose, translator }: IncidentModalProps) => {
  return ReactDOM.createPortal(
    <BasicModal onClose={onClose} className="border border-warning">
      <BasicModalBody className="p-4">
        <div className="d-flex  flex-column w-100">
          <div
            className="rounded-circle p-0 alert alert-discreet-warning bg-warning-alpha-md text-warning mb-4 d-flex align-items-center justify-content-center"
            style={{ width: '3rem', height: '3rem' }}
          >
            <SvgIcon type="warning" width={24} height={24} className="text-warning" />
          </div>
          <h3 className="text-warning mb-5" id="partialIncidentModal">
            {translator.translate('incidents.title')}
          </h3>

          {incidents.sort(sortByCreatedDate).map((incident, index) => (
            <PartialIncidentItem
              key={incident.id}
              incident={incident}
              index={index}
              translator={translator}
              language={language}
              viewed={incidentViewed.includes(incident.id)}
            />
          ))}
        </div>
      </BasicModalBody>
      <BasicModalFooter>
        <button
          type="button"
          className="btn btn-lg sgbs-btn-default btn-default"
          data-dismiss="modal"
          onClick={onClose}
        >
          {translator.translate('incidents.minimize')}
        </button>
      </BasicModalFooter>
    </BasicModal>,
    document.body,
  );
};

export const PartialIncident = ({
  incidents,
  incidentStatus,
  language,
  cleanIncidents,
  translator,
}: PartialIncidentProps) => {
  const [modalDisplayed, setModalDisplayed] = useState(false);
  const [incidentViewed, setIncidentViewed] = useState<number[]>([]);

  if (incidents.length === 0) {
    return null;
  }

  const found = incidents.filter((incident) => incident.id === incidentStatus);
  const incident = found.length === 1 ? found[0] : null;

  const showModal = () => {
    setModalDisplayed(true);
    registerAccountCenterEvent('partial-incidents.open');
  };

  const hideModal = () => {
    setModalDisplayed(false);
    setIncidentViewed(incidents.map((i: IPartialIncident) => i.id));
    registerAccountCenterEvent('partial-incidents.close');
    cleanIncidents();
  };

  const globalStatus = getGlobalStatus(incidents);
  const styleClass = getStyleClass(globalStatus);

  return (
    <>
      <button className={`btn btn-${styleClass} btn-sm d-flex text-left justify-content-start`} onClick={showModal}>
        <div className="text-nowrap" style={{ cursor: 'pointer' }}>
          {(!incident || incident.status !== PARTIAL_INCIDENT_OPEN) &&
            incidents.length > 0 &&
            globalStatus !== PARTIAL_INCIDENT_CLOSED && (
              <span
                className={`badge badge-pill rounded-pill badge-primary-alt fs-12 text-small line-height-1 text-${styleClass} mr-2 me-2`}
              >
                {incidents.length}
              </span>
            )}
          <span className="font-weight-medium">
            {`${
              incident && incident.status === PARTIAL_INCIDENT_OPEN
                ? `${translator.translate('incidents.newIncident')} `
                : ''
            }${translator.translate('incidents.title')}${
              globalStatus === PARTIAL_INCIDENT_CLOSED
                ? ` (${translator.translate('incidents.resolved')})`
                : incident && incident.status === PARTIAL_INCIDENT_CLOSED
                  ? ` (${translator.translate('incidents.updated')})`
                  : ''
            }`}
          </span>
          {incident && incident.status === PARTIAL_INCIDENT_OPEN && (
            <div
              className="d-inline-block align-bottom text-truncate"
              style={{ maxWidth: '200px' }}
            >{` : ${incident.text}`}</div>
          )}
          {incident && incident.status === PARTIAL_INCIDENT_OPEN ? (
            <span className={`ml-3 ms-3 text-nowrap`}>{translator.translate('incidents.seeMore')}</span>
          ) : (
            <i className="sgwt-widgets-icon icon-sm">
              <SvgIcon type="arrow_drop_down" />
            </i>
          )}
        </div>
      </button>
      {modalDisplayed && (
        <IncidentModal
          incidents={incidents}
          incidentViewed={incidentViewed}
          language={language}
          onClose={hideModal}
          translator={translator}
        />
      )}
    </>
  );
};
