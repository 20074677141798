import React, { useRef, useState } from 'react';
import { registerAccountCenterEvent } from '../../../common/monitoring';
import { ITranslatorProps } from '../../../common/sgwt-i18n';
import { IUserDropdownLink } from '../shared/sgwt-account-center.types';
import { CustomLinksButton, UserToolbarDropdown } from './parts';

interface CustomLinksProps extends ITranslatorProps {
  userDropdownLinks?: IUserDropdownLink[];
  onPersonalisationClicked: () => void;
  onUserDropdownLinkClicked: (link: IUserDropdownLink) => void;
  emitEvent: (name: string, detail: any) => void;
}

export const CustomLinks = (props: CustomLinksProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const element = useRef<HTMLDivElement | null>(null);

  const displayDropdown = () => {
    setShowDropdown(true);
    props.onPersonalisationClicked();
    props.emitEvent(`show-custom-links-card`, {});
    registerAccountCenterEvent('custom-links.open');
  };

  const hideDropdown = () => {
    setShowDropdown(false);
    props.onPersonalisationClicked();
    props.emitEvent(`hide-custom-links-card`, {});
    registerAccountCenterEvent('custom-links.close');
  };

  const changeDropdownVisibility = () => {
    if (showDropdown) {
      hideDropdown();
    } else {
      displayDropdown();
    }
  };

  const clickLink = (item: IUserDropdownLink) => {
    registerAccountCenterEvent('custom-links.click-link');
    props.onUserDropdownLinkClicked(item);
  };

  return (
    <div ref={element} className="position-relative">
      <CustomLinksButton onClick={changeDropdownVisibility} title={props.translator.translate('button.customLinks')} />
      {props.userDropdownLinks && (
        <UserToolbarDropdown
          className="sgwt-personalisation"
          rootElement={element.current}
          onHide={hideDropdown}
          visible={showDropdown}
        >
          {props.userDropdownLinks.map((item) => (
            <a
              className="dropdown-item border-0"
              href={item.link}
              target={item.target || ''}
              onClick={() => clickLink(item)}
              key={`perso-link-${item.label}`}
            >
              {item.label}
            </a>
          ))}
        </UserToolbarDropdown>
      )}
    </div>
  );
};
